import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  SearchbarChangeEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import apiConfig from "../../apiConfig";
import "./Wallet.css";
import axios from 'axios';
import { Link, useHistory, useLocation } from "react-router-dom";

import { Network } from '@capacitor/network';

const Wallet: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const history = useHistory();
  const [activeSegment, setActiveSegment] = useState<string>("activeServices");
  const [products, setProducts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(true);
  const [InactiveProducts, setInactiveProducts] = useState<Array<any>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInactiveQuery, setSearchInactiveQuery] = useState('');


  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useIonViewWillEnter(() => {
    if(localStorage.getItem("fcmToken") == "" || localStorage.getItem("fcmToken") == null || localStorage.getItem("fcmToken") == undefined) {
      history.push('/login');
    }
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });

    if(localStorage.getItem("servicesViewed") == "inActive") {
      inactiveService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    } else if (localStorage.getItem("servicesViewed") == "active") {
      activeService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    } else {
      if (activeSegment == "activeServices") {
        activeService();
        setSearchQuery('');
        setSearchInactiveQuery('');
      } else {
        inactiveService();
        setSearchQuery('');
        setSearchInactiveQuery('');
      }
    }
    // checkAndroidVersion();
    // generateItems();
    inactiveCountFunc();
  }, [])
  const inactiveCountFunc = () => {
    axios.post(`${apiConfig.baseUrl}/productslist`, {
      // "partner_id": localStorage.getItem("partnerId"),
      "token": localStorage.getItem("fcmToken"),
      "status": "2"
    })
      .then(res => {
        setInactiveCount(res.data.data.length);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
      })
  }
  const activeService = () => {
    setProducts([]);
    setLoading(true);
    setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/productslist`, {
      // "partner_id": localStorage.getItem("partnerId"),
      "token": localStorage.getItem("fcmToken"),
      "status": "1",
    })
      .then(res => {
        if(res.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setActiveCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setLoading(false);
            setBackdropVisible(false);
            setIsProductListLoading(true);
          } else {
            setProducts(res.data.data);
            setBackdropVisible(false);
            setLoading(false);
            setIsProductListLoading(false);
            // setDisplayIndex(10);
          }
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(() => {
        console.log('Errorrrrr-->>');
        setLoading(false);
        setIsProductListLoading(false);
      })
  }
  const inactiveService = () => {
    setInactiveProducts([]);
    setProducts([]);
    setLoading(true);
    setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/productslist`, {
      // "partner_id": localStorage.getItem("partnerId"),
      "token": localStorage.getItem("fcmToken"),
      "status": "2"
    })
      .then(res => {
        if(res.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setInactiveCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setLoading(false);
            setBackdropVisible(false);
            setIsProductListLoading(true);
          } else {
            setInactiveProducts(res.data.data);
            setBackdropVisible(false);
            setLoading(false);
            setIsProductListLoading(false);
            // setInactiveDisplayIndex(10);
          }
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
        setIsProductListLoading(false);
      })
  }

  const activeServiceSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    console.log("e.detail.value", e.detail.value);
    setSearchQuery(value ?? '');
  };
  const inActiveServiceSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setSearchInactiveQuery(value ?? '');
  };


  const segmentChanged = (e: any) => {
    console.log(activeSegment);
    setActiveSegment(e.detail.value);
    if (e.detail.value == "activeServices") {
      activeService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    } else {
      inactiveService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    }
  };


  const goBack = () => {
    history.goBack();
  };


  const viewService = (e: any, s:any) => {
    localStorage.setItem('productId', e);
    if(s == "active") {
      localStorage.setItem("servicesViewed","active");
    } else if (s == "inActive") {
      localStorage.setItem("servicesViewed","inActive");
    }
  }
  const handleActiveRefresh = async (event: CustomEvent) => {
    setProducts([]);
    axios.post(`${apiConfig.baseUrl}/productslist`, {
      // "partner_id": localStorage.getItem("partnerId"),
      "token": localStorage.getItem("fcmToken"),
      "status": "1",
    })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          event.detail.complete();
        } else {
          setActiveCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setLoading(false);
            event.detail.complete();
          } else {
            setProducts(res.data.data);
            setBackdropVisible(false);
            event.detail.complete();
          }
        }
        setTimeout(() => {
          if (loading == true) {
            event.detail.complete();
          }
        }, 10000);
      })
      .catch(() => {
        console.log('Errorrrrr-->>');
        event.detail.complete();
      })

  };
  const handleInactiveRefresh = async (event: CustomEvent) => {
    setInactiveProducts([]);
    setProducts([]);
    axios.post(`${apiConfig.baseUrl}/productslist`, {
      // "partner_id": localStorage.getItem("partnerId"),
      "token": localStorage.getItem("fcmToken"),
      "status": "2"
    })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          event.detail.complete();

        } else {
          setInactiveCount(res.data.data.length);
          if (res.data.data.length === 0) {
            event.detail.complete();

            setIsProductListLoading(true);
          } else {
            setInactiveProducts(res.data.data);
            event.detail.complete();

            setIsProductListLoading(false);
            // setInactiveDisplayIndex(10);
          }
        }
        setTimeout(() => {
          if (loading == true) {
            event.detail.complete();

          }
        }, 10000);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        event.detail.complete();

        setIsProductListLoading(false);
      })
  };
  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Products and Services
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Products and Services
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {activeSegment === "activeServices" ? (
                <div className="send-money-holder animate__animated animate__fadeInRight animate__slow">
                  <div className="the-title p-add-title ion-padding-horizontal">
                    <IonLabel className="dark-dash-main-title">Add new products / Services</IonLabel>
                  </div>

                  <div className="contact-holder">
                    <Link className="link-decoration" to={{ pathname: '/addproduct' }}>
                      {/* <IonButton routerLink="/addproduct" routerDirection="none"> */}
                      <div className="recip-card ion-text-center">
                        <div className="add-btn-holder">
                          <img src="assets/imgs/icons/plus.svg" alt="" />
                        </div>
                        <p className="ion-text-wrap">
                          Add your <br /> products
                        </p>
                      </div>
                      {/* </IonButton> */}
                    </Link>
                    {products.map((user, i) => (
                      <div key={i} className="recip-card ion-text-center">
                        <IonAvatar>
                          <img src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="" />
                        </IonAvatar>
                        <h6 className='ion-text-wrap slide-img-title fontName scroll-image-title text-ellipsis'>
                          {user.title}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {activeSegment === "activeServices" ? (
                <div className="dark-empty-div">
                  <span> </span>
                </div>
              ) : (
                <div></div>
              )}
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "activeServices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 className="fontName dark-nocardh6">Sorry, there are no Active List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredActiveList = products
                              // .slice(0, displayIndex)
                              .filter(result => (result.title && result.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                                (result.description && result.description.toLowerCase().includes(searchQuery.toLowerCase()))
                              );
                            if (filteredActiveList.length === 0 && searchQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="dark-fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="dark-fontName dark-nocardh6">Sorry, there are no Active Lists for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredActiveList.map((user, i) => (
                                <IonCol key={i} size='6' size-md='3' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id, "active")} to={'/view-services'}>
                                    <IonGrid className='dark-fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol className="col-p0" size='12'>
                                          <div className="dark-fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className='dark-fontName dark-image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>

                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "inactiveservices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 className="fontName dark-nocardh6">Sorry, there are no Inactive List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredInactiveList = InactiveProducts
                              // .slice(0, inactiveDisplayIndex)
                              .filter(result => result.title.toLowerCase().includes(searchInactiveQuery.toLowerCase()) ||
                                result.description.toLowerCase().includes(searchInactiveQuery.toLowerCase())
                              );
                            if (filteredInactiveList.length === 0 && searchInactiveQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName dark-nocardh6">Sorry, there are no Inactive List for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredInactiveList.map((user, i) => (
                                <IonCol key={i} size-md='3' size='6' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id, "inActive")} to={'/view-services'}>
                                    <IonGrid className='fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol col-12 size='12' className="col-p0">
                                          <div className="fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className='dark-fontName dark-image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="light-ion-title">
              Products and Services
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="wallet">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="light-ion-title">
              Products and Services
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="wallet">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>
                    {/* <IonLabel>Active Services {activeCount}</IonLabel> */}
                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="custom-segment-button">
                    {/* <IonLabel>Inactive Services {inactiveCount}</IonLabel> */}
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {activeSegment === "activeServices" ? (
                <div className="send-money-holder animate__animated animate__fadeInRight animate__slow">
                  <div className="the-title p-add-title ion-padding-horizontal">
                    <h3>Add new products / Services</h3>
                  </div>

                  <div className="contact-holder">
                    <Link className="link-decoration" to={{ pathname: '/addproduct' }}>
                      <div className="recip-card ion-text-center">
                        <div className="add-btn-holder">
                          <img src="assets/imgs/icons/plus.svg" alt="" />
                        </div>
                        <p className="ion-text-wrap">
                          Add your <br /> products
                        </p>
                      </div>
                    </Link>
                    {products.map((user, i) => (
                      <div key={i} className="recip-card ion-text-center">
                        <IonAvatar>
                          <img src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="" />
                        </IonAvatar>
                        <h6 style={{ textAlign: "center" }} className='ion-text-wrap slide-img-title fontName image-title text-ellipsis'>
                          {user.title}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {activeSegment === "activeServices" ? (
                <div className="empty-div">
                  <span> </span>
                </div>
              ) : (
                <div></div>
              )}
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "activeServices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 className="fontName nocardh6">Sorry, there are no Active List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredActiveList = products
                              // .slice(0, displayIndex)
                              .filter(result => (result.title && result.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                                (result.description && result.description.toLowerCase().includes(searchQuery.toLowerCase()))
                              );
                            if (filteredActiveList.length === 0 && searchQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName nocardh6">Sorry, there are no Active Lists for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredActiveList.map((user, i) => (
                                <IonCol key={i} size-md='3' size='6' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id, "active")} to={'/view-services'}>
                                    <IonGrid className='fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol col-12 size='12' className="col-p0">
                                          <div className="fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="image-title-card">
                                            <IonLabel className='fontName image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>

                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "inactiveservices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredInactiveList = InactiveProducts
                              // .slice(0, inactiveDisplayIndex)
                              .filter(result => result.title.toLowerCase().includes(searchInactiveQuery.toLowerCase()) ||
                                result.description.toLowerCase().includes(searchInactiveQuery.toLowerCase())
                              );
                            if (filteredInactiveList.length === 0 && searchInactiveQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredInactiveList.map((user, i) => (
                                <IonCol key={i} size-md='3' size='6' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id, "inActive")} to={'/view-services'}>
                                    <IonGrid className='fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol col-12 size='12' className="col-p0">
                                          <div className="fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="image-title-card">
                                            <IonLabel className='fontName image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Wallet;
