import React, { useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import apiConfig from "../../apiConfig";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import "./Forgotpassword.css";

import { Network } from '@capacitor/network';

const Forgotpassword: React.FC = () => {
    const history = useHistory();
    const [unit, setUnit] = useState<string>();
    const goBack = () => {
        history.goBack();
        sendOtpEmail.current = "";
        setInputFPemail('');
        latestInputCnfmPassValue.current = ''
        latestInputNewPassValue.current = ''
        setInputCnfmPass('');
        setInputNewPass('');
        setOtp1('');
        setOtp2('');
        setOtp3('');
        setOtp4('');
    };
    const [inputFPemail, setInputFPemail] = useState('');
    const sendOtpEmail = useRef('');

    const [inputNewPass, setInputNewPass] = useState('');
    const latestInputNewPassValue = useRef('');

    const [inputCnfmPass, setInputCnfmPass] = useState('');
    const latestInputCnfmPassValue = useRef('');

    const [checkCnfmPassError, setCheckCnfmPassError] = useState('');

    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);

    const [showToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showToasSuccess, setShowToastSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [isDarkModeActive, setIsDarkModeActive] = useState(false);

    const [firstFP, setFirstFP] = useState(true);
    const [secondFP, setSecondFP] = useState(false);
    const [thirdFP, setThirdFP] = useState(false);

    const [userId, setUserId] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [cnfmPassError, setCnfmPassError] = useState('');



    const [showPassword, setShowPassword] = useState(false);


    const [formState, setFormState] = useState({
        email: '',
        emailError: '',
        newPass: '',
        newPassError: '',
        cnfmPass: '',
        cnfmPassError: ''
    });
    const [networkStatus, setNetworkStatus] = useState<any>(true);

    const [otp1, setOtp1] = React.useState('');
    const [otp2, setOtp2] = React.useState('');
    const [otp3, setOtp3] = React.useState('');
    const [otp4, setOtp4] = React.useState('');

    // Refs for OTP inputs
    const otp1Ref = useRef<HTMLInputElement>(null);
    const otp2Ref = useRef<HTMLInputElement>(null);
    const otp3Ref = useRef<HTMLInputElement>(null);
    const otp4Ref = useRef<HTMLInputElement>(null);

    const handleOTP = (field: any, value: any) => {
        const isNumeric = value === '' || /^\d+$/.test(value);
        if (!isNumeric) return; // Ensure input is numeric, exit if not

        // Update the state based on the field
        switch (field) {
            case 'otp1':
                setOtp1(value);
                if (value) otp2Ref.current?.focus();
                break;
            case 'otp2':
                setOtp2(value);
                if (value) otp3Ref.current?.focus();
                break;
            case 'otp3':
                setOtp3(value);
                if (value) otp4Ref.current?.focus();
                break;
            case 'otp4':
                setOtp4(value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if(localStorage.getItem("fcmToken") == "" || localStorage.getItem("fcmToken") == null || localStorage.getItem("fcmToken") == undefined) {
            history.push('/login');
          }
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
        });
    }, []);

    useIonViewWillEnter(() => {
        console.log("darkModeValue", localStorage.getItem("darkMode"), localStorage.getItem("fcmToken"));
        if(localStorage.getItem("fcmToken") == null) {
            localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
        }
        if (localStorage.getItem("darkMode") == "darkMode") {
            setIsDarkModeActive(true);
        } else if (localStorage.getItem("darkMode") == null) {
            setIsDarkModeActive(false);
        }
    }, [])

    const handleFPemailInputChanges = (event: any) => {
        // Remove all spaces from email
        const email = event.target.value.replace(/\s+/g, '');
        setInputFPemail(email);
        sendOtpEmail.current = email;

        // Clear the email error if the email is corrected
        if (email) {
            setFormState(prevState => ({
                ...prevState,
                emailError: ''
            }));
        }
    };

    const handleNewPassInputChanges = (event: any) => {
        const newPass = event.target.value.replace(/\s+/g, ''); // Remove spaces
        setInputNewPass(newPass);
        latestInputNewPassValue.current = newPass;
        setPasswordError('');
        setCheckCnfmPassError('');
    };

    const handleCnfmPassInputChanges = (event: any) => {
        const cnfmPass = event.target.value.replace(/\s+/g, ''); // Remove spaces
        setInputCnfmPass(cnfmPass);
        latestInputCnfmPassValue.current = cnfmPass;
        setCnfmPassError('');
        setCheckCnfmPassError('');
    };


    const resendOTP = () => {
        setLoading(true);
        setBackdropVisible(true);
        axios.post(`${apiConfig.baseUrl}/sendotp`, {
            email: inputFPemail
        })
            .then(response => {
                if (response.data.status == false) {
                    setShowErrorToast(true);
                    setErrorMessage(response.data.message);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    setShowToastSuccess(true);
                    // setUserId(response.data.data.id);
                    setSuccessMessage(response.data.message);
                    sendOtpEmail.current = "";
                    setFirstFP(false);
                    setSecondFP(true);
                    setThirdFP(false);
                    setLoading(false);
                    setBackdropVisible(false);
                }

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        //  setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                console.log(error);
                // if (error.response.status == 401) {
                //     setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                //     setSupendedTitle("Inactive");
                //     setShowSuspendedModal(true);
                // }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });
    }

    const submitChangePass = () => {
        if (formState.emailError == '') {
            if (!sendOtpEmail.current) {
                setErrorMessage('Please enter your email');
                setShowErrorToast(true);
                setFormState(prevState => ({
                    ...prevState,
                    emailError: 'Please enter your email'
                }));
            } else {
                setLoading(true);
                setBackdropVisible(true);
                axios.post(`${apiConfig.baseUrl}/sendotp`, {
                    email: sendOtpEmail.current
                })
                    .then(response => {
                        if (response.data.status == false) {
                            setShowErrorToast(true);
                            setErrorMessage(response.data.message);
                            setLoading(false);
                            setBackdropVisible(false);
                        } else {
                            setShowToastSuccess(true);
                            // setUserId(response.data.data.id);
                            setSuccessMessage(response.data.message);
                            sendOtpEmail.current = "";
                            setFirstFP(false);
                            setSecondFP(true);
                            setThirdFP(false);
                            setLoading(false);
                            setBackdropVisible(false);
                        }

                        setTimeout(() => {
                            if (loading == true) {
                                setLoading(false);
                                setBackdropVisible(false);
                                setErrorMessage("Something went wrong on API please try again");
                                //  setShowToast(true);
                            }
                        }, 10000);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.code == "ERR_NETWORK") {
                            setNetworkStatus(false);
                        } else {
                            setNetworkStatus(true);
                        }
                        // if (error.response.status == 401) {
                        //     setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                        //     setSupendedTitle("Inactive");
                        //     setShowSuspendedModal(true);
                        // }
                        setLoading(false);
                        setBackdropVisible(false);
                    });
            }
        } else {
            setErrorMessage('Please enter valid email');
            setFormState(prevState => ({
                ...prevState,
                emailError: 'Please enter valid email'
            }));
            setShowErrorToast(true);
        }
    }


    const sendOTP = () => {

        if (otp1 !== '' && otp2 !== '' && otp3 !== '' && otp4 !== '') {
            setLoading(true);
            setBackdropVisible(true);
            axios.post(`${apiConfig.baseUrl}/verify_otp`, {
                // user_id: userId,
                otp_code: otp1 + otp2 + otp3 + otp4
            })
                .then(response => {
                    if (response.data.status == false) {
                        setShowErrorToast(true);
                        setErrorMessage(response.data.message);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        setShowToastSuccess(true);
                        setSuccessMessage(response.data.message);
                        // setOtp1('');
                        // setOtp2('');
                        // setOtp3('');
                        // setOtp4('');
                        setFirstFP(false);
                        setSecondFP(false);
                        setThirdFP(true);
                        setLoading(false);
                        setBackdropVisible(false);
                    }

                    setTimeout(() => {
                        if (loading == true) {
                            setLoading(false);
                            setBackdropVisible(false);
                            setErrorMessage("Something went wrong on API please try again");
                            //  setShowToast(true);
                        }
                    }, 10000);
                })
                .catch(error => {
                    console.log(error);
                    if (error.code == "ERR_NETWORK") {
                        setNetworkStatus(false);
                    } else {
                        setNetworkStatus(true);
                    }
                    setShowErrorToast(true);
                    setErrorMessage(error.response.data.message);
                    if (error.response.status == 401) {
                        setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                        setSupendedTitle("Inactive");
                        setShowSuspendedModal(true);
                    }
                    setLoading(false);
                    setBackdropVisible(false);
                });
        } else {
            setErrorMessage('Please Fill the One Time Password numbers');
            setShowErrorToast(true);
        }
    }

    const clearPassword = () => {
        latestInputCnfmPassValue.current = ''
        latestInputNewPassValue.current = ''
        setInputCnfmPass('');
        setInputNewPass('')
        setCheckCnfmPassError('');
    }

    const submitFP = () => {
        // Clear previous error messages
        setPasswordError('');
        setCnfmPassError('');
        setCheckCnfmPassError('');

        if (latestInputNewPassValue.current !== '' && latestInputCnfmPassValue.current !== '') {

            // Check if new password matches the confirm password
            if (latestInputNewPassValue.current !== latestInputCnfmPassValue.current) {
                setCheckCnfmPassError('New Password and Confirm password do not match');
                setErrorMessage('New Password and Confirm password do not match');
                setShowErrorToast(true);
                return;
            }

            // If there are no errors, proceed with the API call
            setLoading(true);
            setBackdropVisible(true);
            axios.post(`${apiConfig.baseUrl}/resetpassword`, {
                // user_id: userId,
                otp_code: otp1 + otp2 + otp3 + otp4,
                password: latestInputCnfmPassValue.current,
                confirmPassword: latestInputNewPassValue.current
            })
                .then(response => {
                    if (response.data.status === false) {
                        setShowErrorToast(true);
                        setErrorMessage(response.data.message);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        setShowToastSuccess(true);
                        setSuccessMessage(response.data.message);
                        sendOtpEmail.current = "";
                        setInputFPemail('');
                        latestInputCnfmPassValue.current = '';
                        latestInputNewPassValue.current = '';
                        setInputCnfmPass('');
                        setInputNewPass('');
                        history.push('/login');
                        setFirstFP(true);
                        setSecondFP(false);
                        setThirdFP(false);
                        setLoading(false);
                        setBackdropVisible(false);
                    }
                    setTimeout(() => {
                        if (loading === true) {
                            setLoading(false);
                            setBackdropVisible(false);
                            setErrorMessage("Something went wrong on API please try again");
                        }
                    }, 10000);
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 401) {
                        setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                        setSupendedTitle("Inactive");
                        setShowSuspendedModal(true);
                    }
                    if (error.code === "ERR_NETWORK") {
                        setNetworkStatus(false);
                    } else {
                        setNetworkStatus(true);
                    }
                    setLoading(false);
                    setBackdropVisible(false);
                });
        } else {
            setErrorMessage('Please Enter both the passwords');
            setShowErrorToast(true);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <IonPage>
            {networkStatus == false && isDarkModeActive == false &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start" className="back-btn">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-center">
                                Forgot Password
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="requestmoney">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && isDarkModeActive == false &&
                <>
                    {firstFP == true &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="light-mode-header-toolbar">
                                    <IonButtons slot="start" className="back-btn">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/backArrow.svg"
                                                slot="end" />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center">
                                        Forgot Password
                                    </IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="requestmoney">

                                <div className="bg-fp"></div>
                                <div className="gradient-overlay t-d-none m-d-none"></div>
                                <div className="web-fp">
                                    <div className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <IonImg className="change-password-img" src="assets/imgs/images/forgotPassLock.svg" alt=""></IonImg>
                                                    <IonLabel className="change-password-label">Please enter your email address to <br />recieve a verification code.</IonLabel>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <div className="model-container">
                                                        <input
                                                            className='form-control-model-fp fontName'
                                                            value={inputFPemail}
                                                            placeholder="Enter your email"
                                                            onChange={handleFPemailInputChanges}
                                                            onFocus={(e) => e.preventDefault()}
                                                        ></input>
                                                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Email Address</label>
                                                    </div>
                                                    {formState.emailError && <span style={{ marginTop: "-2%" }} className='dark-fontName emailErrorMsg'>{formState.emailError}</span>}
                                                </IonCol>
                                                <IonCol size="12">
                                                    <IonButton expand="block" onClick={submitChangePass} className="send-fp primary-button">
                                                        Send
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                </div>
                                <IonLoading
                                    isOpen={loading}
                                    spinner="circular"
                                    translucent={true}
                                />
                                <IonToast
                                    isOpen={showToast}
                                    onDidDismiss={() => setShowErrorToast(false)}
                                    message={errorMessage}
                                    duration={3000}
                                    color="danger" />
                                <IonToast
                                    isOpen={showToasSuccess}
                                    onDidDismiss={() => setShowToastSuccess(false)}
                                    message={successMessage}
                                    duration={3000}
                                    color="success" />
                            </IonContent>
                        </>
                    }
                    {secondFP == true &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="light-mode-header-toolbar">
                                    <IonButtons slot="start" className="back-btn">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/backArrow.svg"
                                                slot="end" />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center">
                                        Verify Your Email
                                    </IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="requestmoney">
                                <div className="bg-fp"></div>
                                <div className="gradient-overlay t-d-none m-d-none"></div>
                                <div className="web-fp">
                                    <IonImg className="change-password-img" src="assets/imgs/images/emailOTP.svg" alt=""></IonImg>
                                    <IonLabel className="change-password-label">Please enter the 4 digit code sent to<br /> {inputFPemail}</IonLabel>

                                    <div style={{ marginTop: "15%" }} className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="3">
                                                    <div className="model-container">
                                                        <input
                                                            type='tel'
                                                            className='form-control-model-otp fontName'
                                                            value={otp1}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const newValue = value.slice(-1); // Take the last character
                                                                handleOTP('otp1', newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Backspace" && otp1 === '') {
                                                                    // For otp1, there's no previous input to focus, but you might add logic if needed for specific behavior
                                                                }
                                                            }}
                                                            ref={otp1Ref}
                                                        ></input>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <div className="model-container">
                                                        <input
                                                            type='tel'
                                                            className='form-control-model-otp fontName'
                                                            value={otp2}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const newValue = value.slice(-1);
                                                                handleOTP('otp2', newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Backspace") {
                                                                    const target = e.target as HTMLInputElement;
                                                                    if (target.value === '') {
                                                                        otp1Ref.current?.focus();
                                                                    }
                                                                }
                                                            }}
                                                            ref={otp2Ref}
                                                        ></input>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <div className="model-container">
                                                        <input
                                                            type='tel'
                                                            className='form-control-model-otp fontName'
                                                            value={otp3}
                                                            onChange={(e) => {
                                                                // Take the last character of the input to ensure only a single character is used
                                                                const newValue = e.target.value.slice(-1);
                                                                handleOTP('otp3', newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Backspace" && otp3 === '') {
                                                                    // If backspace is pressed and the field is empty, focus shifts to the previous input
                                                                    otp2Ref.current?.focus();
                                                                }
                                                            }}
                                                            ref={otp3Ref}
                                                        ></input>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <div className="model-container">
                                                        <input
                                                            type='tel'
                                                            className='form-control-model-otp fontName'
                                                            value={otp4}
                                                            onChange={(e) => {
                                                                // Take the last character of the input to ensure only a single character is used
                                                                const newValue = e.target.value.slice(-1);
                                                                handleOTP('otp4', newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Backspace" && otp4 === '') {
                                                                    // If backspace is pressed and the field is empty, focus shifts to the previous input
                                                                    otp3Ref.current?.focus();
                                                                }
                                                            }}
                                                            ref={otp4Ref}
                                                        ></input>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <IonLabel onClick={resendOTP} class="resend-otp">Resend Code</IonLabel>
                                                    <IonButton expand="block" onClick={sendOTP} className="send-fp primary-button">
                                                        Verify
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                </div>
                                <IonLoading
                                    isOpen={loading}
                                    spinner="circular"
                                    translucent={true}
                                />
                                <IonToast
                                    isOpen={showToast}
                                    onDidDismiss={() => setShowErrorToast(false)}
                                    message={errorMessage}
                                    duration={3000}
                                    color="danger" />
                                <IonToast
                                    isOpen={showToasSuccess}
                                    onDidDismiss={() => setShowToastSuccess(false)}
                                    message={successMessage}
                                    duration={3000}
                                    color="success" />
                            </IonContent>
                        </>
                    }
                    {thirdFP == true &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="light-mode-header-toolbar">
                                    <IonButtons slot="start" className="back-btn">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/backArrow.svg"
                                                slot="end" />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center">
                                        Create New Password
                                    </IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="requestmoney">
                                <div className="bg-fp"></div>
                                <div className="gradient-overlay t-d-none m-d-none"></div>
                                <div className="web-fp">
                                    <IonImg className="change-password-img" src="assets/imgs/images/changePassword.svg" alt=""></IonImg>
                                    <IonLabel className="change-password-label">Your new password must be different<br /> from previously used password.</IonLabel>

                                    <div style={{ marginTop: "15%" }} className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="12" col-12>
                                                    <div className="model-container">
                                                        <input
                                                            className='form-control-model-fp fontName'
                                                            value={inputNewPass}
                                                            onChange={handleNewPassInputChanges}
                                                            onFocus={(e) => e.preventDefault()}
                                                        ></input>
                                                        <label className="label-outline-model-fp" htmlFor="textAreaExample">New Password</label>
                                                    </div>
                                                    {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                                                    {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                                                </IonCol>
                                                <IonCol size="12" col-12>
                                                    <div className="model-container">
                                                        <input
                                                            className='form-control-model-fp fontName'
                                                            value={inputCnfmPass}
                                                            onChange={handleCnfmPassInputChanges}
                                                            onFocus={(e) => e.preventDefault()}
                                                        ></input>
                                                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                                                        {cnfmPassError && (<span className='fontName emailErrorMsg'>{cnfmPassError}</span>)}
                                                    </div>
                                                    <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <IonLabel onClick={clearPassword} class="resend-otp">Clear Passwords</IonLabel>
                                                    <IonButton expand="block" onClick={submitFP} type="submit" className="send-fp primary-button">
                                                        Submit
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                </div>
                                <IonLoading
                                    isOpen={loading}
                                    spinner="circular"
                                    translucent={true}
                                />
                                <IonToast
                                    isOpen={showToast}
                                    onDidDismiss={() => setShowErrorToast(false)}
                                    message={errorMessage}
                                    duration={3000}
                                    color="danger" />
                                <IonToast
                                    isOpen={showToasSuccess}
                                    onDidDismiss={() => setShowToastSuccess(false)}
                                    message={successMessage}
                                    duration={3000}
                                    color="success" />
                            </IonContent>
                        </>
                    }
                </>
            }
            {networkStatus == false && isDarkModeActive == true &&
                <><IonHeader className="ion-no-border">
                    <IonToolbar className="dark-mode-header-toolbar">
                        <IonButtons slot="start" className="back-btn">
                            <IonButton
                                className="end-btn"
                                onClick={goBack}
                            >
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/darkBackArrow.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                        <IonTitle className="ion-text-center dark-ion-title">
                            Forgot Password
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                className="end-btn"
                                routerLink="/notifications"
                                routerDirection="none"
                            >
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/darkBell.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                    <IonContent fullscreen className="dark-profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && isDarkModeActive == true &&
                <><IonHeader className="ion-no-border">
                    <IonToolbar className="dark-mode-header-toolbar">
                        <IonButtons slot="start" className="back-btn">
                            <IonButton
                                className="end-btn"
                                onClick={goBack}
                            >
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/darkBackArrow.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                        <IonTitle className="ion-text-center dark-ion-title">
                            Forgot Password
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                className="end-btn"
                                routerLink="/notifications"
                                routerDirection="none"
                            >
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/darkBell.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                    <IonContent fullscreen className="dark-profile">
                        <div>
                            <IonImg className="change-password-img" src="assets/imgs/images/darkChangePassword.svg" alt=""></IonImg>
                            <IonLabel className="dark-change-password-label">Your new password must be different from previously used password.</IonLabel>
                        </div>
                        <div style={{ marginTop: "15%" }} className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                            <IonGrid>
                                <IonRow>
                                    {/* <IonCol size="12" col-12>
                                        <div className="model-container">
                                            <input
                                                className='dark-form-control-model-fp fontName'
                                                value={inputOldPass}
                                                onChange={e => handleOldPassInputChanges(e)}
                                                onFocus={(e) => e.preventDefault()}
                                            ></input>
                                            <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Old Password</label>
                                        </div>
                                        {formState.oldPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.oldPassError}</span>}
                                    </IonCol> */}
                                    <IonCol size="12" col-12>
                                        <div className="model-container">
                                            <input
                                                className='dark-form-control-model-fp fontName'
                                                value={inputNewPass}
                                                onChange={e => handleNewPassInputChanges(e)}
                                                onFocus={(e) => e.preventDefault()}
                                            ></input>
                                            <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">New Password</label>
                                        </div>
                                        {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <div className="model-container">
                                            <input
                                                className='dark-form-control-model-fp fontName'
                                                value={inputCnfmPass}
                                                onChange={e => handleCnfmPassInputChanges(e)}
                                                onFocus={(e) => e.preventDefault()}
                                            ></input>
                                            <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                                        </div>
                                        {/* {formState.cnfmPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.cnfmPassError}</span>} */}
                                        <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <IonButton expand="block" onClick={submitChangePass} className="submit-fp ">
                                            Submit
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
                            <IonContent className="dark-model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }}
                                                className='fontName booking-details-btn'
                                                onClick={() => setShowSuspendedModal(false)}
                                                expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                            <IonToast
                                isOpen={showToast}
                                onDidDismiss={() => setShowErrorToast(false)}
                                message={errorMessage}
                                duration={3000}
                                color="danger" />
                        <IonToast
                            isOpen={showToasSuccess}
                            onDidDismiss={() => setShowToastSuccess(false)}
                            message={successMessage}
                            duration={3000}
                            color="success" />
                    </IonContent></>
            }
        </IonPage>
    );
};

export default Forgotpassword;
