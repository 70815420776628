import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonAlert
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { getLatestVersion } from './services/versionService';
import { Capacitor } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Getstarted from "./pages/Getstarted/Getstarted";
import Intro from "./pages/Intro/Intro";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import MainTabs from "./pages/Tabs/MainTabs";

import Requestmoney from "./pages/Requestmoney/Requestmoney";
import Requestreview from "./pages/Requestreview/Requestreview";
import Reviewandsend from "./pages/Reviewandsend/Reviewandsend";
import Sendmoney from "./pages/Sendmoney/Sendmoney";
import Successmodal from "./pages/Modals/Successmodal/Successmodal";
import Addincome from "./pages/Addincome/Addincome";
import Send from "./pages/Send/Send";
import Notifications from "./pages/Notifications/Notifications";
import Addproduct from "./pages/Addproduct/Addproduct";
import Viewbooking from "./pages/Viewbooking/Viewbooking";
import Notificationdetail from "./pages/Notificationdetail/Notificationdetails";
import Activehealthstatus from "./pages/Activehealthstatus/Activehealthstatus";
// import { PushNotifications } from '@capacitor/push-notifications';
import Viewprofile from "./pages/Request/Request";
import Forgotpassword from "./pages/Forgotpassword/Forgotpassword";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
// import firebase from 'firebase';
setupIonicReact({
  mode: "md",
});

const App: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const tabClassName = darkModeValue === "darkMode" ? "dark-mode-tabs" : "light-mode-tabs";
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);

  useEffect(() => {
    const checkForUpdates = async () => {
      const currentVersion = '1.0.0';
      const latestVersion = await getLatestVersion();
      console.log("Latest Version:", latestVersion);

      if (latestVersion && compareVersions(currentVersion, latestVersion) < 0) {
        setShowUpdateAlert(true);
      }
    };

    checkForUpdates();
  }, []);

  const compareVersions = (currentVersion: string, latestVersion: string): number => {
    const current = currentVersion.split('.').map(Number);
    const latest = latestVersion.split('.').map(Number);

    for (let i = 0; i < current.length; i++) {
      if (latest[i] > current[i]) return -1;
      if (latest[i] < current[i]) return 1;
    }
    return 0;
  };

  const handleUpdate = () => {
    console.log("Platform:", Capacitor.getPlatform());

    if (Capacitor.getPlatform() === 'ios') {
      window.open('itms-apps://apps.apple.com/app/idcom.afh.afhpartner');
    } else if (Capacitor.getPlatform() === 'android') {
      window.open('market://details?id=com.afhapp.afhpartner');
    }
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>

          <Route component={NotFoundPage} />

          <Route
            path="/tabs"
            render={() => <MainTabs darkModeValue={tabClassName} />}
          />
          <Route exact path="/Getstarted">
            <Getstarted />
          </Route>
          <Route exact path="/intro">
            <Intro />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          
          <Route exact path="/sign-up">
            <Signup />
          </Route>

          <Route exact path="/home">
            <Home />
          </Route>

          <Route exact path="/Viewprofile">
            <Viewprofile />
          </Route>

          <Route exact path="/password/reset">
            <Forgotpassword />
          </Route>

          <Route exact path="/change-password">
            <Requestmoney />
          </Route>

          <Route exact path="/send">
            <Send />
          </Route>
          <Route exact path="/sendmoney">
            <Sendmoney />
          </Route>
          <Route component={Activehealthstatus} path="/Activehealthstatus" />
          <Route component={Requestreview} path="/View-Categories" />
          <Route path="/reviewandsend" component={Reviewandsend} />
          <Route exact path="/successmodal">
            <Successmodal />
          </Route>
          <Route exact path="/notifications">
            <Notifications />
          </Route>
          <Route
            exact
            path="/notificationDetail"
            component={Notificationdetail}
          />
          <Route path="/view-services" component={Addincome} />
          <Route path="/addproduct" component={Addproduct} />
          <Route path="/viewbooking" component={Viewbooking} />
          <Route exact path="/">
             <Redirect to="/Getstarted" />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
      <IonAlert
        isOpen={showUpdateAlert}
        header={'Update Available'}
        message={'A new version of the app is available. Please update to continue.'}
        buttons={[
          {
            text: 'Update',
            handler: handleUpdate
          }
        ]}
      />
    </IonApp>
  );
};

export default App;
