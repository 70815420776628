import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  IonItem,
  IonFooter,
  useIonViewWillEnter,
} from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import axios from "axios";
import apiConfig from "../../apiConfig";

import "./Signup.css";
import OldFooter from "../../components/OldFooter";
// import AddressAutocomplete from "../../components/Mapcomponent";

const Signup: React.FC = () => {


  const history = useHistory();
  // localStorage.setItem("fcmToken", "APA91bEVc2hGSDGG245gwrgrwgAI2SlP4fcvwegvfcVRERGwefvWsrgrgEGHwrgWRG235GWluCOviMsACgNZdcHEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
  const [formData, setFormData] = useState({
    "companyName": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "password": "",
    "fcm_token": localStorage.getItem('fcmToken'),
    "mobile": "",
    "website": null,
    "postcode":"",
    "category": []
  });

  const [showPassword, setShowPassword] = useState(false);
  const [resetInput, setResetInput] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [networkStatus, setNetworkStatus] = useState<any>(true);

  const [phoneError, setPhoneError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState("");
  const [os, setOS] = useState('other');

  const [servicesList, setServicesList] = useState<Array<any>>([]);
  const [postcodeList, setPostcodeList] = useState<Array<any>>([]);
  const [postcodeError, setPostcodeError] = useState('');

  const [inputBwebsite, setInputBwebsite] = useState('');
  const latestInputBwebsiteValue = useRef('');

  const [inputAddressOne, setInputAddressOne] = useState('');
  const latestInputAddressOneValue = useRef('');

  const [blurDropDown, setBlurDropDown] = useState(false);
  const [PClong, setPClong] = useState('');
  const [PClat, setPClat] = useState('');

  const selectedService = useRef('');


  function getPlatforms() {
    // Simple check for desktop or mobile platform
    if (navigator.userAgent.indexOf('Mobi') > -1) {
        return "mobile";
    } else {
        return "desktop";
    }
}

  useEffect(() => {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macosPlatforms.includes(platform)) {
      setOS('macos');
    } else if (windowsPlatforms.includes(platform)) {
      setOS('windows');
    }

    getServices();
    resetForm();

  }, []);

  useIonViewWillEnter(() => {
      handleAuthentication();
  }, []);

  const handleAuthentication = async () => {
    const loggedOut = localStorage.getItem("loggedOut");
    const fcmToken = localStorage.getItem("fcmToken");
    if(fcmToken == "" || fcmToken == null || fcmToken == undefined){
      localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
    } else if (loggedOut == "unsuccesful" || loggedOut == null) {
      await logout();
    }
  };

  const logout = async () => {
    try {
      const res = await axios.post(`${apiConfig.baseUrl}/logout`, {
        token: localStorage.getItem("fcmToken"),
      });

      if (res.data.suspendstatus === false) {
        setSupendedMsg(res.data.message);
        setSupendedTitle(res.data.title);
        setShowSuspendedModal(true);
      } else if (res.data.status === true) {
        localStorage.setItem("loggedOut", "succesfully");
        setLoading(false);
        setBackdropVisible(false);
        return true;
      } else {
        throw new Error("Logout failed");
      }
    } catch (error) {
      console.error("Logout error:", error);
      setLoading(false);
      setBackdropVisible(false);
      return false;
    }
  };


  const goBack = () => {
    resetForm();
    history.push('/login');
  };

  const resetForm = () => {
    setFormData({
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      fcm_token: localStorage.getItem('fcmToken'),
      mobile: "",
      website : null,
      postcode: "",
      category: []
    });
    setIsChecked(false);
    setEmailError('');
    setPasswordError('');
    setPhoneError('');
    setServiceError('');
    setResetInput(true); // Ensure this is set to true to trigger reset in AddressAutocomplete
  };

  // const handlePlaceSelect = (place: any) => {
  //   const latitude = place.geometry.location.lat();
  //   const longitude = place.geometry.location.lng();

  //   setFormData({
  //     ...formData,
  //     actual_address: place.formatted_address,
  //     lat: latitude,
  //     lng: longitude
  //   });
  // };

  // const handleInputChange = () => {
  //   setFormData({
  //     ...formData,
  //     actual_address: "",
  //     lat: "",
  //     lng: ""
  //   });
  // };

  // const handleResetDone = () => {
  //   setResetInput(false);
  // };

  // const handleTogglePassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleCheckboxChange = (event: any) => {
  //   setIsChecked(event.target.checked);
  // };

  const handleAddressOneInputChanges = (event: any) => {
    latestInputAddressOneValue.current = '';
    setPClong('');
    setPClat('');
    setInputAddressOne(event.target.value);
    getPostCode(event.target.value);
    setBlurDropDown(true);
  }

  const handleOptionClick = (option: string) => {
    setBlurDropDown(true);
    console.log("option", option);
    setInputAddressOne(option);
    latestInputAddressOneValue.current = option

    setFormData((prevData) => ({
      ...prevData,
      postcode: option,
    }));

  };

  const handleBwebsiteInputChanges = (event: any) => {
    const bWebsite = event.target.value;
    setInputBwebsite(bWebsite);
    latestInputBwebsiteValue.current = bWebsite;
  

    setFormData((prevData) => ({
      ...prevData,
      website: bWebsite,
    }));
  };

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChanges = (field: string, value: string) => {
    if (field === "email" || field === 'password') {
      value = value.replace(/\s+/g, '');
    }

    setFormData({
      ...formData,
      [field]: value,
    });

    if (field === "email") {
      setEmailError('');
    }

    if (field === 'password') {
      setPasswordError('');
    }

    if (field === 'mobile') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

    if (field === 'mobile') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

  };

  const handleServiceChange = (event: CustomEvent) => {
    const selectedServices = event.detail.value;
    console.log("selectedServices",selectedServices);
    selectedService.current = selectedServices
    setFormData((prevData) => ({
      ...prevData,
      category: selectedServices,
    }));
    setServiceError("");
  };

  const getPostCode = (postcode: any) => {
    // setLoading(true);
    // setBackdropVisible(true);
    if (postcode) {
      setPostcodeError('');
      axios.get(`https://api.postcodes.io/postcodes?q=${postcode}&format=json`)
        .then(response => {
          console.log("response", response);
          setPostcodeList(response.data.result)
        })
        .catch(error => {
          console.log(error);
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
        });
    } else {
      setPostcodeList([]);
      setPostcodeError("Please Enter a valid postcode")
    }
  }

  const getServices = () => {

    // setLoading(true);
    // setBackdropVisible(true);

    axios.post(`https://afh.cloud/api/categoriesautocomplete?q=`, {
      headers: {
        // 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
       setServicesList(res.data);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
         
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const createAccount = () => {
    let emailValid = true;
    let passwordValid = true;
    let phoneValid = true;
    let addressValid = true;

    if (formData.email !== "" && formData.firstName !== "" && formData.lastName !== "" && formData.companyName !== "" && formData.password !== "" && formData.mobile !== "") {
      if (formData.email === "") {
        setEmailError("Invalid Email address");
        emailValid = false;
      } else {
        setEmailError("");
      }

      if (formData.password === "") {
        setPasswordError("Password is required");
        passwordValid = false;
      } else {
        setPasswordError("");
      }

      if(selectedService.current == ''){
        setServiceError('Please select alteast one services')
      }

      const isValidPhoneNumber = formData.mobile.length === 11 && formData.mobile.startsWith('0');
      if (!isValidPhoneNumber) {
        setPhoneError(
          formData.mobile.length !== 11
            ? 'Phone number should be 11 digits long'
            : 'Phone number should start with 0'
        );
        phoneValid = false;
      } else {
        setPhoneError('');
      }

      if (latestInputAddressOneValue.current === "") {
        setErrorMessage("Please select the postcode from the dropdown");
        addressValid = false;
      }

      if (emailValid && passwordValid && phoneValid && addressValid) {
        setLoading(true);
        setBackdropVisible(true);
        axios.post(`${apiConfig.baseUrl}/signup`, formData)
          .then(response => {
            if (response.data.status === false) {
              setShowErrorToast(true);
              setErrorMessage(response.data.message);
              setLoading(false);
              setBackdropVisible(false);
            } else {
             
            }
            if (response.data.suspendstatus === false) {
              setSupendedMsg(response.data.message);
              setSupendedTitle(response.data.title);
              setShowSuspendedModal(true);
            } else if (response.data.status === true) {
              localStorage.setItem("name", response.data.name);
              localStorage.setItem("tradingName", response.data.ownername);
              localStorage.setItem("companyName", response.data.companyname);
              localStorage.setItem("email", response.data.email);
              localStorage.setItem("displaypicture", response.data.displaypicture);
              localStorage.setItem("mobile", response.data.mobile);
              localStorage.setItem("postcode", response.data.postcode);
              localStorage.setItem("expensesInfo", "info");
              localStorage.setItem("newUser", "yes");
              localStorage.setItem("fromSignup", "yes");
              localStorage.setItem("servicesViewed", "active");
              localStorage.setItem("loggedOut","unsuccesful")
              setShowToastSuccess(true);
              setSuccessMessage(response.data.message);
              resetForm();
              setResetInput(true);
              setShowPassword(false);
              setLoading(false);
              setBackdropVisible(false);
      
              history.push("/tabs/dashboard");
            } else {
              setErrorMessage(response.data.message);
              setLoading(false);
            }
            setTimeout(() => {
              if (loading === true) {
                setLoading(false);
                setBackdropVisible(false);
                setErrorMessage("Something went wrong on API please try again");
                setShowErrorToast(true);
              }
            }, 10000);
          })
          .catch(error => {
            if (error.response.status === 401) {
              setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
              setSupendedTitle("Inactive");
              setShowSuspendedModal(true);
            }
            if (error.code === "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });

      } else {
        if (!emailValid) {
          setErrorMessage("Please enter a valid email");
        } else if (!passwordValid) {
          setErrorMessage("Please enter a valid password");
        } else if (!phoneValid) {
          setErrorMessage("Please enter a valid Phone Number");
        }
        setShowErrorToast(true);
      }
    } else {
      setErrorMessage("Please enter the required fields");
      setShowErrorToast(true);
    }
  };

  return (
    <IonPage>
      {/* {getPlatforms() == "mobile" && (
      <IonHeader className="ion-no-border">
        <IonToolbar className="light-mode-header-toolbar signup-toolbar">
          <IonButtons slot="start">
            <IonButton
              className="end-btn"
              onClick={goBack}
            >
              <IonIcon
                className="fav header-icons"
                src="assets/imgs/icons/backArrow.svg"
                slot="end" />
            </IonButton>
          </IonButtons>
          <IonTitle style={{ textAlign: "center" }}>
            Signup Here
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      )} */}
      {/* {getPlatforms() == "desktop" && ( */}
                <IonHeader className="">
                    <IonToolbar className="lnd-header">
                        <div className="lnd-flx">
                        <IonImg className="lnd-header-img" src="assets/imgs/images/afh-logo-landscape-white.svg"></IonImg>
                        <div className="m-d-none lnd-btns-div">
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/sign-up"
                        routerDirection="none"
                        style={{ marginRight: "5%" }}
                        >Join us</IonButton>
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/login"
                        routerDirection="none">Login</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
    {/* )} */}
      <IonContent className="signup-bg-content ion-padding-horizontal" fullscreen>
        <div className="bg-signup">
        <div className="gradient-overlay t-d-none m-d-none"></div>
        <div className={`${os} the-form signup-grid`}>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
              <IonButton 
                expand="block" 
                routerLink="/login"
                routerDirection="none"
                className="sign-up-btn">
                Already have an account?
              </IonButton>
              </IonCol>
              <IonCol size="12">
                <IonLabel className="top-title m-d-none t-d-none d-title">Become a Partner</IonLabel>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={e => handleInputChanges('companyName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Company Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Email"
                    value={formData.email}
                    onChange={e => handleInputChanges('email', e.target.value)}
                  ></input>
                  {emailError && (<span className='fontName emailErrorMsg'>{emailError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Email<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={e => handleInputChanges('firstName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">First Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={e => handleInputChanges('lastName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Last Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>

              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className="form-control-model-fp-signup fontName"
                    placeholder="Phone"
                    value={formData.mobile}
                    minLength={11}
                    maxLength={11}
                    onChange={(e) => handleInputChanges('mobile', e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Phone Number<span style={{ color: '#FF0000' }}>*</span></label>
                  {phoneError && <p className='fontName emailErrorMsg'>{phoneError}</p>}
                </div>
              </IonCol>

              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    style={{ background: "rgba(255, 255, 255, 0)" }}
                    className='form-control-model-fp-signup fontName'
                    placeholder="Password"
                    value={formData.password}
                    onChange={e => handleInputChanges('password', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  />
                  {/* <IonIcon
                    className='eye-icon eye-icon-signup'
                    onClick={handleTogglePassword}
                    icon={showPassword ? eyeOffOutline : eyeOutline}
                  /> */}
                  {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="password">Password<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>

              <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp-signup fontName'
                          value={inputAddressOne}
                          onChange={e => handleAddressOneInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                          placeholder="Postcode"
                        // onDrop={focusOut}
                        ></input>
                        <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Postcode<span className="span-star-clr">*</span></label>
                      </div>
                      {/* <div className="dropdown"> */}
                      {postcodeList !== null &&
                        <div className={postcodeList.length == 0 || latestInputAddressOneValue.current !== '' || blurDropDown == false ? "dropdown-content" : "dropdown-content-avail"}>
                          {postcodeList.map((post: any, index: any) => (
                            <IonItem className="fontName" key={index} onClick={() => { handleOptionClick(post.postcode); setPClong(post.longitude); setPClat(post.latitude); }}>
                              {post.postcode}
                            </IonItem>
                          ))}
                        </div>
                      }
                      {postcodeList == null &&
                        <div className={blurDropDown == false ? "dropdown-content" : "dropdown-content-avail-null"}>
                          <h6 className="fontName" style={{ marginTop: "20%" }}>No results found</h6>
                        </div>
                      }
                      {/* </div> */}
                      <span className='fontName emailErrorMsg'>{postcodeError}</span>
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp-signup fontName'
                          value={inputBwebsite}
                          placeholder="https://example.com/"
                          onChange={e => handleBwebsiteInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Website</label>
                      </div>
                    </IonCol>

              <IonCol size="12">
              <div className="model-container-signup">
              <IonSelect className='sign-up-services-input form-control-model-fp-signup fontName' interface="popover" placeholder="Select Services" multiple={true} onIonChange={handleServiceChange}>
                  {servicesList && servicesList.map((ser: any) => (
                     <IonSelectOption key={ser.id} value={ser.id}>{ser.title}</IonSelectOption>
                  ))}
            </IonSelect>
            <label className="label-outline-model-fp-signup" htmlFor="services">Services<span style={{ color: '#FF0000' }}>*</span></label>
            {serviceError && <p className='fontName emailErrorMsg'>{serviceError}</p>}
            </div>
                  </IonCol>

                    {/* <IonCol size='12' col-12>
                      <div className="checkbx-div">
                        <IonCheckbox
                          checked={isChecked}
                          className='fontName'
                          mode="ios"
                          onIonChange={handleCheckboxChange}
                        ></IonCheckbox>
                        <IonLabel className="checkbx-label">I Agree with the <span className='fontName'><a className="checkbx-span" href='https://glamdeva.com/terms'>Terms & Conditions</a></span></IonLabel>
                      </div>
                    </IonCol> */}

                    <IonCol size='12' col-12>
                      {/* <div className="btn-holder btn-holder-signup"> */}
                        <IonButton expand="block" onClick={createAccount} className="bottom-btn-sign-up login-button">
                          Signup
                        </IonButton>
                        {/* <IonLabel className="create-account">Already have a account? <Link style={{ textDecoration: "none" }} to="/login"><span className="create-account-span">Login</span></Link></IonLabel> */}
                      {/* </div> */}
                    </IonCol>
            </IonRow>
          </IonGrid>
            </div>
        </div>
          <OldFooter />
        <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
          <IonContent className="model-bg">
            <IonGrid className='cnfmModelGrid'>
              <IonRow>
                <IonCol size="12" col-12>
                  <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonButton
                    routerLink="/login"
                    routerDirection="none"
                    style={{ width: "100%", margin: "auto" }} 
                    className='fontName booking-details-btn' 
                    onClick={() => { setShowSuspendedModal(false) }} 
                    expand="block"
                    >
                    Back to Login
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonLoading
          isOpen={loading}
          spinner="circular"
          translucent={true}
        />

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={3000}
          color="danger" 
          />

        <IonToast
          isOpen={showToasSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message={successMessage}
          duration={3000}
          color="success" 
          />

      </IonContent>

    </IonPage >
  );
};

export default Signup;
