import {
    IonButton,
    IonButtons,
    IonCard,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import apiConfig from "../../apiConfig";
import "./Viewbooking.css";
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Geolocation } from '@ionic-native/geolocation';
import { addOutline, removeOutline } from "ionicons/icons";
import React from "react";

import { Network } from '@capacitor/network';

const Viewbooking: React.FC = () => {

    const darkModeValue = localStorage.getItem("darkMode");

    const history = useHistory();
    const validInput = /^[A-Za-z0-9 \n]*$/;
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);

    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [showRejectModal, setShowRejectModal] = useState(false);

    const [activeAccordionIndex, setActiveAccordionIndex] = useState([true, false, false]);
    // type LocationState = {
    //     data: null;
    // }
    // const location = useLocation<LocationState>();
    // const Id = location.state?.data;
    const [bookingId, setBookingId] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    const [booking, setbooking] = useState<Array<any>>([]);
    const [customerDistance, setCustomerDistance] = useState<any>();
    const [scrollImages, setScrollImages] = useState<Array<any>>([]);
    const [oneImage, setOneImage] = useState<Array<any>>([]);

    const [selectedValue, setSelectedValue] = useState('');
    const [rejecttextarea, setRejectTextArea] = useState("");
    const latestInputAcceptValue = useRef('');
    const latestInputRejectValue = useRef('');
    const latestInputRejectDescriptionValue = useRef('');
    const [textareaErrorMessage, setTextareaErrorMessage] = useState('');
    const [cnfmNameErrorMessage, setCnfmNameErrorMessage] = useState('');
    const [rejectNameErrorMessage, setRejectNameErrorMessage] = useState('');
    const [isRejectChecked, setIsRejectChecked] = useState(false);
    const [rejectname, setRejectName] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [expired, setExpired] = useState(Boolean);
    const [showToast, setShowToast] = useState(false);

    const [confirmname, setFormState] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showRejectCnfmModal, setShowRejectCnfmModal] = useState(false);
    const [showRejectedModal, setShowRejectedModal] = useState(false);



    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    const [networkStatus, setNetworkStatus] = useState<any>(true);
    useEffect(() => {
        if(localStorage.getItem("fcmToken") == "" || localStorage.getItem("fcmToken") == null || localStorage.getItem("fcmToken") == undefined) {
            history.push('/login');
          }
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
        });
    }, []);

    useIonViewWillEnter(() => {
        setLoading(true);
        setBackdropVisible(true);
        axios.post(`${apiConfig.baseUrl}/ViewBookingList`, {
            "bookingId": localStorage.getItem("bookingId"),
            // "partner_id": localStorage.getItem("partnerId"),
            "token": localStorage.getItem("fcmToken"),
            "status": "2"
        })
            .then(response => {
                if(response.data.message == "User not found") {
                    setLoading(false);
                    setBackdropVisible(false);
                    history.push('/login');
                  } else {
                if (response.data.suspendstatus == false) {
                    setSupendedMsg(response.data.message);
                    setSupendedTitle(response.data.title);
                    setShowSuspendedModal(true);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    setbooking(response.data.data);
                    setCustomerDistance(response.data.distance_between_partner_and_customer);
                    setExpired(response.data.expired)
                    localStorage.setItem("confirmed", "");
                    if (response.data.images.length == 1) {
                        setOneImage(response.data.images);
                        setScrollImages([]);
                    } else {
                        setOneImage([]);
                        setScrollImages(response.data.images);
                    }
                    setBookingId(response.data.data[0].id);
                    setBookingStatus(response.data.data[0].status);
                    if (response.data.expired === true) {
                        localStorage.setItem("expired", "expired");
                    } else {
                        if (response.data.data[0].status === 3 || response.data.data[0].status === 4) {
                            localStorage.setItem("expensesInfo", "info");
                        } else if (response.data.data[0].status === 2 && response.data.expired === false) {
                            localStorage.setItem("confirmed", "confirmed");
                        }  
                    }
                    
                    setLoading(false);
                    setBackdropVisible(false);
                }
            }
                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                console.log(error);
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });
        getLocation();
    }, [])
    const goBack = () => {
        history.goBack();
    };
    const handlesetRejectTextAreaInput = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue === "") {
            console.log("event.target", inputValue);
            setRejectTextArea(inputValue);
            latestInputRejectDescriptionValue.current = inputValue;
            setTextareaErrorMessage("");  // clear the error message
        } else if (inputValue.startsWith(' ')) {
            setTextareaErrorMessage("Input cannot start with a space.");
        } else if (!validInput.test(inputValue)) {
            setTextareaErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
        } else {
            console.log("event.target", inputValue);
            setRejectTextArea(inputValue);
            latestInputRejectDescriptionValue.current = inputValue;
            setTextareaErrorMessage("");  // clear the error message
        }
    }
    const handleCnfmInput = (event: any) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            setFormState(inputValue);
            latestInputAcceptValue.current = inputValue;
            setCnfmNameErrorMessage("");  // clear the error message
        } else if (inputValue.startsWith(' ')) {
            setCnfmNameErrorMessage("Input cannot start with a space.");
        } else if (!validInput.test(inputValue)) {
            setCnfmNameErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
        } else {
            setFormState(inputValue);
            latestInputAcceptValue.current = inputValue;
            setCnfmNameErrorMessage("");  // clear the error message
        }
    }
    const handleRejectInput = (event: any) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            console.log("event.target", inputValue);
            setRejectName(inputValue);
            latestInputRejectValue.current = inputValue;
            setRejectNameErrorMessage("");  // clear the error message
        } else if (inputValue.startsWith(' ')) {
            setRejectNameErrorMessage("Input cannot start with a space.");
        } else if (!validInput.test(inputValue)) {
            setRejectNameErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
        } else {
            console.log("event.target", inputValue);
            setRejectName(inputValue);
            latestInputRejectValue.current = inputValue;
            setRejectNameErrorMessage("");  // clear the error message
        }
    }

    const handleSelectChange = (event: any) => {
        console.log("event.target.value", event.target.value);
        setSelectedValue(event.target.value);
    };
    const closeShowModal = () => {
        setFormState("");
        setIsChecked(false);
        setShowModal(false);
        setTextareaErrorMessage('');
        setCnfmNameErrorMessage('');
        setRejectNameErrorMessage('');
        latestInputAcceptValue.current = ""
        latestInputRejectDescriptionValue.current = "";
        latestInputRejectValue.current = ""
    }

    const confirm = () => {
        if (latestInputAcceptValue.current == "" || isChecked == false) {
            setErrorMessage("Please enter your name and click the checkbox");
            setShowToast(true);
        } else {
            setLoading(true);
            setBackdropVisible(true);
            axios.post(`${apiConfig.baseUrl}/confirmandreject`, {
                "token": localStorage.getItem("fcmToken"),
                // "partner_id": localStorage.getItem("partnerId"),
                bookingid: localStorage.getItem("bookingId"),
                feedbacktype: "confirm",
                confirmname: latestInputAcceptValue.current
            })
                .then(res => {
                    if (res.data.suspendstatus == false) {
                        setSupendedMsg(res.data.message);
                        setSupendedTitle(res.data.title);
                        setShowSuspendedModal(true);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        if (res.data.status == true) {
                            localStorage.setItem("confirmed", "Confirmed")
                            localStorage.setItem('bookingId', res.data.data.bookingid);
                            setBookingStatus(res.data.details.status);
                            setbooking(res.data.details);
                            setFormState("");
                            latestInputAcceptValue.current = ""
                            setIsChecked(false);
                            setLoading(false);
                            setTextareaErrorMessage('');
                            setCnfmNameErrorMessage('');
                            setShowConfirmedModal(true);
                            setShowModal(false);
                            setRejectNameErrorMessage('');
                            setBackdropVisible(false);
                        } else {
                            setErrorMessage(res.data.data.response);
                            setShowToast(true);
                        }
                    }
                    setTimeout(() => {
                        if (loading == true) {
                            setLoading(false);
                            setBackdropVisible(false);
                            // setErrorMessage("Something went wrong on API please try again");
                            // setShowToast(true);
                        }
                    }, 10000);
                })
                .catch((error) => {
                    console.log('Errorrrrr-->>', error);
                    if (error.code == "ERR_NETWORK") {
                        setNetworkStatus(false);
                    } else {
                        setNetworkStatus(true);
                    }
                    setLoading(false);
                    setBackdropVisible(false);
                })
        }
    }
    const reject = () => {
        setLoading(true);
        setBackdropVisible(true);
        axios.post(`${apiConfig.baseUrl}/confirmandreject`, {
            token: localStorage.getItem("fcmToken"),
            // "partner_id": localStorage.getItem("partnerId"),
            bookingid: localStorage.getItem("bookingId"),
            feedbacktype: "reject",
            reason: selectedValue,
            notes: latestInputRejectDescriptionValue.current,
            rejectname: latestInputRejectValue.current
        })
            .then(res => {
                if (res.data.suspendstatus == false) {
                    setSupendedMsg(res.data.message);
                    setSupendedTitle(res.data.title);
                    setShowSuspendedModal(true);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    if (res.data.status == true) {
                        setIsRejectChecked(false);
                        setbooking(res.data.details);
                        setShowRejectCnfmModal(false);
                        setShowRejectedModal(true);
                        setRejectName("");
                        setSelectedValue("");
                        setRejectTextArea("");
                        setTextareaErrorMessage('');
                        setCnfmNameErrorMessage('');
                        setRejectNameErrorMessage('');
                        latestInputRejectDescriptionValue.current = "";
                        latestInputRejectValue.current = ""
                        setbooking([]);
                        // unConfirmFunction();
                        // setShowRejectedAlert(true);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        setErrorMessage(res.data.data.response);
                        setShowToast(true);
                        setLoading(false);
                        setBackdropVisible(false);
                    }
                }
                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch((error) => {
                console.log('Errorrrrr-->>', error);
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            })
    }

    const toggleAccordion = (index: any) => {
        const updatedState = activeAccordionIndex.map((_, i) => i === index);
        setActiveAccordionIndex(updatedState);
        // const updatedState = activeAccordionIndex.map((state: any, i: any) => (i === index ? !state : state));
        // setActiveAccordionIndex(updatedState);
    };

    const isAccordionActive = (index: any) => activeAccordionIndex[index];


    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };
    const handleRejectCheckboxChange = (event: any) => {
        setIsRejectChecked(event.target.checked);
    };
    const handleOkayClick = () => {
        if (selectedValue == "" || latestInputRejectDescriptionValue.current == "" || latestInputRejectValue.current == "" || isRejectChecked == false) {
            setErrorMessage("Please enter all the inputs and click the checkbox");
            setShowToast(true);
        } else {
            setShowRejectCnfmModal(true);
            setShowRejectModal(false);
        }
    }
    const closeRejectModal = () => {
        setRejectName("");
        setSelectedValue("");
        setRejectTextArea("");
        setTextareaErrorMessage('');
        setCnfmNameErrorMessage('');
        setRejectNameErrorMessage('');
        latestInputAcceptValue.current = "";
        latestInputRejectDescriptionValue.current = "";
        latestInputRejectValue.current = "";
        setIsRejectChecked(false);
        setShowRejectModal(false);
    };
    const closeRejectCnfmModal = () => {
        setRejectName("");
        setSelectedValue("");
        setRejectTextArea("");
        setTextareaErrorMessage('');
        setCnfmNameErrorMessage('');
        setRejectNameErrorMessage('');
        latestInputAcceptValue.current = "";
        latestInputRejectDescriptionValue.current = "";
        latestInputRejectValue.current = "";
        setIsRejectChecked(false);
        setShowRejectCnfmModal(false)
    };

    const getLocation = async () => {
        try {
          const position = await Geolocation.getCurrentPosition();
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          console.log("Current Position:", position.coords.latitude, position.coords.longitude);
          return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        } catch (error) {
          console.error('Error getting current location:', error);
          return null;
        }
      };
      
      const handleGoogleMapLink = async (postCode: any) => {
        if (!postCode) {
          console.error('Invalid post code.');
          return;
        }
      
        if (latitude && longitude) {
          window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${postCode}`);
        } else {
          const location = await getLocation();
          if (location) {
            window.open(`https://www.google.com/maps/dir/${location.latitude},${location.longitude}/${postCode}`);
          } else {
            console.error('Unable to fetch location.');
            setShowToast(true);
            setErrorMessage("Could not fetch location. Please enable location services.")
            alert('Could not fetch location. Please enable location services.');
          }
        }
      };

      const handleGoogleMapLinkTo = async (toPostCode: any) => {
        if (!toPostCode) {
          console.error('Invalid post code.');
          return;
        }
      
        if (latitude && longitude) {
          window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${toPostCode}`);
        } else {
          const location = await getLocation();
          if (location) {
            window.open(`https://www.google.com/maps/dir/${location.latitude},${location.longitude}/${toPostCode}`);
          } else {
            console.error('Unable to fetch location.');
            setShowToast(true);
            setErrorMessage("Could not fetch location. Please enable location services.")
            alert('Could not fetch location. Please enable location services.');
          }
        }
      };

    return (
        <IonPage>
            {networkStatus == false && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="dark-mode-header-toolbar center-div">
                            <IonButtons slot="start" className="back-btn">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBackArrow.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-left dark-ion-title">
                                Booking Information
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBell.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="dark-mode-ion-content">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="dark-mode-header-toolbar">
                            <IonButtons slot="start" className="back-btn">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBackArrow.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-left dark-ion-title">
                                Booking Information
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBell.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="dark-mode-ion-content">
                        <div className="center-div">
                            <div className="the-title booking-title-top ion-padding-horizontal">
                                <IonLabel className="dark-bookingid-label">Booking ID: {bookingId}
                                    {expired == false &&
                                        bookingStatus == "3" &&
                                        <span className="modified-chip">Unconfirmed</span>
                                    }
                                    {localStorage.getItem("confirmed") == "Confirmed" &&
                                        <span className="confirmed-chip">Confirmed</span>
                                    }
                                    {bookingStatus == "2" &&
                                        <span className="confirmed-chip">Confirmed</span>
                                    }
                                    {bookingStatus == "4" &&
                                        <span className="modified-chip">Modified</span>
                                    }
                                    {expired == true &&
                                        <span className="modified-chip">Completed</span>
                                    }
                                </IonLabel>
                            </div>
                            <div className="slider-holder animate__animated animate__fadeInRight">
                                {oneImage.length == 0 && (
                                    scrollImages.length == 0 && (
                                        <IonImg src="assets/imgs/images/no-image.jpg" alt="Product" className="no-image-vd"></IonImg>
                                    )
                                )}
                                {oneImage.length == 1 && (
                                    oneImage.map((img, i) => (
                                        <div key={i}>
                                            <IonImg src={img.src} alt="Product" className="no-image-vd" />
                                        </div>
                                    ))
                                )}
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    // arrows={true}
                                    autoplay={true}
                                    pagination={true}
                                    spaceBetween={10} // Add some space between the slides
                                    breakpoints={{
                                        // For mobile devices
                                        320: { // Adjust this breakpoint to the minimum width you want to target
                                            slidesPerView: 1.2,
                                            spaceBetween: 10 // You can adjust this value as needed
                                        },
                                        // For tablets
                                        768: { // Adjust this breakpoint to the width you want to target for tablets
                                            slidesPerView: 3,
                                            spaceBetween: 20
                                        },
                                        // For desktops
                                        1024: {
                                            slidesPerView: 5,
                                            spaceBetween: 30
                                        }
                                    }}
                                >
                                    {scrollImages.length > 1 && (
                                        scrollImages !== null && (
                                            scrollImages.map((img, i) => (
                                                <div key={i}>
                                                    <SwiperSlide>
                                                        <div className="slide-content ion-text-center ion-padding-horizontal">
                                                            <div className="card" id="darkPrimary">
                                                                <IonImg src={img.src} alt="Product" className="product-detail-img" />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </div>
                                            ))
                                        )
                                    )}
                                </Swiper>
                            </div>
                            {booking.map((user, i) => {
                                return (
                                    <div key={i}>
                                        {expired === false && (
                                            <>
                                                {user.status == 3 && (
                                                    <>
                                                        <IonGrid className="btn-grid">
                                                            <IonRow className="btn-row">
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => setShowRejectModal(true)} className="dark-reject-btn">
                                                                        Reject Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => { setShowModal(true) }} className="booking-details-btn">
                                                                        Accept Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                        <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='dark-service-d-title' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='dark-arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                                <IonLabel className="dark-font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.from_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.to_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="dark-sec-col">
                                                                            {/* */}
                                                                            <IonLabel className="dark-value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="dark-first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="dark-font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                                        <IonLabel className="dark-value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })

                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                 <IonRow style={{ borderBottom: "1px solid #D9D9D908", borderRight: "1px solid #D9D9D908", borderLeft: "1px solid #D9D9D908" }}>
                                                                 <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                     <IonLabel className="dark-font-color">Notes From AFH</IonLabel>
                                                                 </IonCol>
                                                                 <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                     <IonLabel className="dark-value-color"> </IonLabel>
                                                                 </IonCol>
                                                                 <IonCol size="12">
                                                                     <div style={{
                                                                         overflow: "auto",
                                                                         overflowY: "scroll",
                                                                         height: "unset",
                                                                         background: "#212124",
                                                                         padding: "5px"
                                                                     }}>
                                                                         <IonLabel className="dark-value-color">{user.partnernotes}</IonLabel>
                                                                     </div>
                                                                 </IonCol>
                                                             </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="dark-payment-details payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="dark-payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="dark-p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12" col-12>
                                                                    <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                        Go Back
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                                {user.status == 2 && (
                                                    <>
                                                        <IonItem lines="none" detail={false} button className='dark-service-d-title' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='dark-arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Customer Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="dark-first-col" size="5" col-5>
                                                                        <IonLabel className="dark-font-color">Customer Name</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="7" col-7>
                                                                        <IonLabel className="dark-value-color">{user.lead.firstname} {user.lead.lastname}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="5" col-5>
                                                                        <IonLabel className="dark-font-color">Mobile Number</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="7" col-7>
                                                                        <IonLabel className="dark-value-color">{user.lead.mobile}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="5" col-5>
                                                                        <IonLabel className="dark-font-color">Email</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="7" col-7>
                                                                        <div>
                                                                            <IonLabel style={{ lineHeight: "1.5" }} className="dark-value-color">{user.lead.email}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <IonItem lines="none" button detail={false} className="dark-service-d-title" onClick={() => toggleAccordion(1)}>
                                                            <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='dark-arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(1) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                                <IonLabel className="dark-font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <div className="dark-location-div">
                                                                            <img className="img-align compass-img" src="assets/imgs/icons/compass.svg" />
                                                                            <IonLabel className="dark-location-text"
                                                                                onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}
                                                                            >
                                                                                Pickup Location
                                                                            </IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.from_address}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.from_ext_address?.map((address: any, index: 0) => (
                                                                        <React.Fragment key={index}>
                                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                                <IonLabel className="dark-font-color">{index + 1}. Extra Pickup Location</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{address}</IonLabel>
                                                                            </IonCol>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <div className="dark-location-div">
                                                                            <img className="img-align compass-dp-lcn" src="assets/imgs/icons/compass.svg" />
                                                                            <IonLabel className="dark-location-text"
                                                                                onClick={() => handleGoogleMapLinkTo(user.lead_details.to_postcode)}
                                                                            >
                                                                                Drop Location
                                                                            </IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.to_address}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="dark-sec-col">
                                                                            <IonLabel className="dark-value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_details.to_ext_address?.map((address: any, index: 0) => (
                                                                        <>
                                                                            <IonCol className="dark-first-col" size="6" col-6 key={index}>
                                                                                <IonLabel className="dark-font-color">{index + 1}. Extra Drop Location</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{address}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    ))}
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="dark-first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="dark-font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                                        <IonLabel className="dark-value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })

                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow style={{ borderBottom: "1px solid #D9D9D908", borderRight: "1px solid #D9D9D908", borderLeft: "1px solid #D9D9D908" }}>
                                                                    <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Notes From AFH</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                        <IonLabel className="dark-value-color"> </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="12">
                                                                        <div style={{
                                                                            overflow: "auto",
                                                                            overflowY: "scroll",
                                                                            height: "unset",
                                                                            background: "#212124",
                                                                            padding: "5px"
                                                                        }}>
                                                                            <IonLabel className="dark-value-color">{user.partnernotes}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="dark-payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="dark-payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="dark-p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" className="pickup-btn" onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}>
                                                                        <IonIcon
                                                                            className="fav btn-icon"
                                                                            src="assets/imgs/icons/location-btn.svg"
                                                                            slot="end"
                                                                        /><span className="order-span">Pickup Location</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                                                        <IonButton expand="block" className="call-btn">
                                                                            <IonIcon
                                                                                className="fav btn-icon"
                                                                                src="assets/imgs/icons/call-btn.svg"
                                                                                slot="end"
                                                                            /><span className="order-span">Call Customer</span>
                                                                        </IonButton>
                                                                    </a>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                                {user.status == 4 && (
                                                    <>
                                                        <IonGrid className="btn-grid">
                                                            <IonRow className="btn-row">
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => setShowRejectModal(true)} className="dark-reject-btn">
                                                                        Reject Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => { setShowModal(true) }} className="booking-details-btn">
                                                                        Accept Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                        <IonItem lines="none" style={{ marginTop: "0%" }} detail={false} button className='dark-service-d-title' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='dark-arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                                <IonLabel className="dark-font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.from_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.to_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="dark-sec-col">
                                                                            <IonLabel className="dark-value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="dark-first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="dark-font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                                        <IonLabel className="dark-value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })
                                                                    )}
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="dark-sec-col">
                                                                        <IonLabel className="dark-value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow style={{ borderBottom: "1px solid #D9D9D908", borderRight: "1px solid #D9D9D908", borderLeft: "1px solid #D9D9D908" }}>
                                                                    <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Notes From AFH</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                        <IonLabel className="dark-value-color"> </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="12">
                                                                        <div style={{
                                                                            overflow: "auto",
                                                                            overflowY: "scroll",
                                                                            height: "unset",
                                                                            background: "#212124",
                                                                            padding: "5px"
                                                                        }}>
                                                                            <IonLabel className="dark-value-color">{user.partnernotes}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="dark-payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="dark-payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="dark-p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="dark-balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label dark-balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12" col-12>
                                                                    <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                        Go Back
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {expired == true &&
                                            <>
                                                <IonItem lines="none" detail={false} button className='dark-service-d-title' onClick={() => toggleAccordion(0)}>
                                                    <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='dark-arrowIcon' />
                                                    <IonLabel className="details-title" slot="start">Customer Details</IonLabel>
                                                </IonItem>
                                                <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                    <IonGrid className='status3-card'>
                                                        <IonRow>
                                                            <IonCol className="dark-first-col" size="5" col-5>
                                                                <IonLabel className="dark-font-color">Customer Name</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="7" col-7>
                                                                <IonLabel className="dark-value-color">{user.lead.firstname} {user.lead.lastname}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" size="5" col-5>
                                                                <IonLabel className="dark-font-color">Mobile Number</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="7" col-7>
                                                                <IonLabel className="dark-value-color">{user.lead.mobile}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" size="5" col-5>
                                                                <IonLabel className="dark-font-color">Email</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="7" col-7>
                                                                <div>
                                                                    <IonLabel style={{ lineHeight: "1.5" }} className="dark-value-color">{user.lead.email}</IonLabel>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                                <IonItem lines="none" button detail={false} className="dark-service-d-title" onClick={() => toggleAccordion(1)}>
                                                    <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='dark-arrowIcon' />
                                                    <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                </IonItem>
                                                <div className={`accordion-content ${isAccordionActive(1) ? 'show' : ''}`}>
                                                    <IonGrid className='status3-card'>
                                                        <IonRow>
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel className="dark-font-color">Service</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.product.category.title}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel className="dark-font-color">Product</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.product.title}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel className="dark-font-color">Event Date & Time</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                            </IonCol>
                                                            {user.lead_details.returndatetimedmY && (
                                                                <>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">Return Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                </>
                                                            )}
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel style={{ textDecoration: "none" }} className="dark-location-text">
                                                                    Pickup Location
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.from_postcode}</IonLabel>
                                                            </IonCol>
                                                            {user.lead_details.from_ext_address?.map((address: any, index: 0) => (
                                                                <React.Fragment key={index}>
                                                                    <IonCol className="dark-first-col" size="6" col-6>
                                                                        <IonLabel className="dark-font-color">{index + 1}. Extra Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{address}</IonLabel>
                                                                    </IonCol>
                                                                </React.Fragment>
                                                            ))}
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel style={{ textDecoration: "none" }} className="dark-location-text">
                                                                    Drop Location
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.to_postcode}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel className="dark-font-color">Customer Distance</IonLabel>
                                                            </IonCol>
                                                            {customerDistance &&
                                                                <IonCol size="6" col-6 className="dark-sec-col">
                                                                    <IonLabel className="dark-value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                </IonCol>
                                                            }
                                                            {user.lead_details.to_ext_address?.map((address: any, index: 0) => (
                                                                <>
                                                                    <IonCol className="dark-first-col" size="6" col-6 key={index}>
                                                                        <IonLabel className="dark-font-color">{index + 1}. Extra Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="dark-sec-col" size="6" col-6>
                                                                        <IonLabel className="dark-value-color">{address}</IonLabel>
                                                                    </IonCol>
                                                                </>
                                                            ))}
                                                            {user.lead_inputs && (
                                                                user.lead_inputs.map((lead: any, i: any) => {
                                                                    return (
                                                                        <>
                                                                            <IonCol className="dark-first-col" size="6" col-6 key={i}>
                                                                                <IonLabel className="dark-font-color">{lead.input_property.title}</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                                <IonLabel className="dark-value-color">{lead.inputvalue}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    );
                                                                })

                                                            )}
                                                            <IonCol className="dark-first-col" size="6" col-6>
                                                                <IonLabel className="dark-font-color">Trip Type</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-sec-col" size="6" col-6>
                                                                <IonLabel className="dark-value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow style={{ borderBottom: "1px solid #D9D9D908", borderRight: "1px solid #D9D9D908", borderLeft: "1px solid #D9D9D908" }}>
                                                            <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                <IonLabel className="dark-font-color">Notes From AFH</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="dark-first-col" style={{ border: "none" }} size="6" col-6>
                                                                <IonLabel className="dark-value-color"> </IonLabel>
                                                            </IonCol>
                                                            <IonCol size="12">
                                                                <div style={{
                                                                    overflow: "auto",
                                                                    overflowY: "scroll",
                                                                    height: "unset",
                                                                    background: "#212124",
                                                                    padding: "5px"
                                                                }}>
                                                                    <IonLabel className="dark-value-color">{user.partnernotes}</IonLabel>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                                <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                    <IonLabel className="dark-payment-details">Payment Details</IonLabel>
                                                </div>
                                                <IonCard className="dark-payment-details-card">
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="dark-font-color">Your Price</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="p-details-label dark-value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="dark-font-color">Fee</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="p-details-label dark-value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className="dark-p-details-r">
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="dark-balance-pay">Balance to Collect from Customer</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="p-details-last-col" size="6" col-6>
                                                                <IonLabel className="p-details-label dark-balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCard>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="12" col-12>
                                                            <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                Go Back
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                            <IonModal id="dark-example-modal" isOpen={showModal} backdropDismiss={false}>
                                <IonHeader className="ion-no-border">
                                    <IonToolbar className="fontName bgToolbar">
                                        <IonButtons slot="start">
                                        </IonButtons>
                                        <IonTitle className='fontName dark-model-title ionTitle'>Booking ID: {localStorage.getItem('bookingId')}
                                            {bookingStatus == "3" &&
                                                <span className="popup-modified-chip">Unconfirmed</span>
                                            }
                                            {bookingStatus == "2" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "4" &&
                                                <span className="popup-modified-chip">Modified</span>
                                            }
                                        </IonTitle>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <div className="model-sub-div">
                                                    <IonLabel className='fontName dark-model-sub-title'>Are you sure do you want confirm this booking?</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <input type="text" id="textAreaExample" value={confirmname}
                                                        onFocus={(e) => e.preventDefault()}
                                                        onChange={handleCnfmInput}
                                                        className="dark-form-control-model" />
                                                    <label className="dark-label-outline-model" htmlFor="textAreaExample">Your Name</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{cnfmNameErrorMessage}</div>
                                                {/* <IonLabel style={{ marginLeft: "0%" }} className='fontName inputTitle'>Enter Your Name</IonLabel>
                                    <IonToolbar className='InputIonToolbar'>
                                        <input
                                            className='cnfmModelInput fontName'
                                            placeholder='Name'
                                            value={confirmname}
                                            onFocus={(e) => e.preventDefault()}
                                            onChange={handleCnfmInput}
                                        ></input>
                                    </IonToolbar>
                                    <div className='fontName emailErrorMsg'>{cnfmNameErrorMessage}</div> */}
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div className='fontName model-sub-div'>
                                                    <IonLabel className="dark-model-note">Note : Enter the name of the person confirming the booking</IonLabel>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow style={{ margin: "-4px 0px 7px 0px" }}>
                                            <IonCol size='12' col-12>
                                                <div className="checkbx-div">
                                                    <IonCheckbox
                                                        checked={isChecked}
                                                        className='fontName'
                                                        mode="ios"
                                                        onIonChange={handleCheckboxChange}
                                                    ></IonCheckbox>
                                                    <IonLabel className="checkbx-label">I Agree with the <span className='fontName'><a className="checkbx-span" href='https://anythingforhire.com/terms'>Terms & Conditions</a></span></IonLabel>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeShowModal} className='fontName dark-reject-btn' type='submit'>
                                                    Close
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={confirm} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Submit
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showConfirmedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/tick.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-b-cnfmed-title'>Booking Confirmed!</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-thanks-cnfmed-title'>Thank you for confirming the Booking ID: {localStorage.getItem('bookingId')}</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <IonButton style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowConfirmedModal(false)} expand="block">
                                                    Back to Booking
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal id="dark-example-modal-reject" isOpen={showRejectModal} backdropDismiss={false}>
                                <IonHeader className="ion-no-border">
                                    <IonToolbar className="fontName bgToolbar">
                                        <IonButtons slot="start">
                                        </IonButtons>
                                        <IonTitle className='fontName dark-model-title ionTitle'>Booking ID: {localStorage.getItem('bookingId')}
                                            {bookingStatus == "3" &&
                                                <span className="popup-modified-chip">Unconfirmed</span>
                                            }
                                            {localStorage.getItem("confirmed") == "Confirmed" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "2" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "4" &&
                                                <span className="popup-modified-chip">Modified</span>
                                            }
                                        </IonTitle>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <div className="model-sub-div">
                                                    <IonLabel className='fontName dark-model-sub-title'>Are you sure do you want reject this Booking?</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <IonSelect interface="popover" className='fontName dark-form-control-model' placeholder="--select reason--" value={selectedValue} onIonChange={handleSelectChange}>
                                                        <IonSelectOption value="">Choose Option</IonSelectOption>
                                                        <IonSelectOption value="Wrong Price">Wrong Price</IonSelectOption>
                                                        <IonSelectOption value="No Vehicle">No Vehicle</IonSelectOption>
                                                    </IonSelect>
                                                    <label className="dark-label-outline-model" htmlFor="textAreaExample">Reason for Rejection</label>
                                                </div>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div style={{ marginTop: "4%" }} className="model-container">
                                                    <textarea
                                                        placeholder="Type something here"
                                                        rows={4}
                                                        className='fontName dark-form-control-model text-area-css'
                                                        value={rejecttextarea}
                                                        onChange={handlesetRejectTextAreaInput}
                                                    ></textarea>
                                                    <label className="dark-label-outline-model" htmlFor="textAreaExample">Notes</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{textareaErrorMessage}</div>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div className="checkbx-div">
                                                    <IonCheckbox
                                                        className='fontName'
                                                        mode="ios"
                                                        checked={isRejectChecked}
                                                        onIonChange={handleRejectCheckboxChange}
                                                    ></IonCheckbox>
                                                    <IonLabel className="checkbx-label">I am authorised for this booking to be rejected</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <input
                                                        className='dark-form-control-model fontName'
                                                        placeholder='Name'
                                                        value={rejectname}
                                                        onChange={handleRejectInput}
                                                    ></input>
                                                    <label className="dark-label-outline-model" htmlFor="textAreaExample">Enter Your Name</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{rejectNameErrorMessage}</div>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectModal} className='fontName dark-reject-btn' type='submit'>
                                                    Close
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={handleOkayClick} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Submit
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showRejectCnfmModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-b-cnfmed-title'>Do you want to Reject?</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-thanks-cnfmed-title'>Are you sure you want to reject the Booking ID: {localStorage.getItem('bookingId')}?</IonLabel>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectCnfmModal} className='fontName dark-reject-btn' type='submit'>
                                                    Cancel
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={reject} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Okay
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showRejectedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/cancel.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-b-cnfmed-title'>Booking Rejected!</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-thanks-cnfmed-title'>Booking ID: {localStorage.getItem('bookingId')} has been rejected as per your request.</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <Link style={{ textDecoration: "none" }} to={"/tabs/bookings"} onClick={() => setShowRejectedModal(false)}>
                                                    <IonButton style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' expand="block">
                                                        Back to Booking
                                                    </IonButton>
                                                </Link>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonLoading
                                isOpen={loading}
                                spinner="circular"
                                translucent={true}
                            />
                            <IonToast
                                isOpen={showToast}
                                onDidDismiss={() => setShowToast(false)}
                                message={errorMessage}
                                duration={3000}
                                color="danger"
                            />
                            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="dark-model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <IonButton
                                                    routerLink="/login"
                                                    routerDirection="none"
                                                    style={{ width: "100%", margin: "auto" }}
                                                    className='fontName booking-details-btn'
                                                    onClick={() => {
                                                        closeRejectCnfmModal();
                                                        closeShowModal();
                                                        setShowSuspendedModal(false);
                                                    }}
                                                    expand="block">
                                                    Back to Login
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start" className="back-btn">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-left">
                                Booking Information
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="wallet">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start" className="back-btn">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-left">
                                Booking Information
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="wallet">
                        <div className="center-div">
                            <div className="the-title booking-title-top ion-padding-horizontal">
                                <IonLabel className="bookingid-label">Booking ID: {bookingId}
                                    {expired == false &&
                                        bookingStatus == "3" &&
                                        <span className="modified-chip">Unconfirmed</span>
                                    }
                                    {localStorage.getItem("confirmed") == "Confirmed" &&
                                        <span className="confirmed-chip">Confirmed</span>
                                    }
                                    {bookingStatus == "2" &&
                                        <span className="confirmed-chip">Confirmed</span>
                                    }
                                    {bookingStatus == "4" &&
                                        <span className="modified-chip">Modified</span>
                                    }
                                    {expired == true &&
                                        <span className="modified-chip">Completed</span>
                                    }
                                </IonLabel>
                            </div>
                            <div className="slider-holder animate__animated animate__fadeInRight">
                                {oneImage.length == 0 && (
                                    scrollImages.length == 0 && (
                                        <IonImg src="assets/imgs/images/no-image.jpg" alt="Product" className="no-image-vd"></IonImg>
                                    )
                                )}
                                {oneImage.length == 1 && (
                                    oneImage.map((img, i) => (
                                        <div key={i}>
                                            <IonImg src={img.src} alt="Product" className="no-image-vd" />
                                        </div>
                                    ))
                                )}
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    autoplay={true}
                                    pagination={true}
                                    spaceBetween={10} // Add some space between the slides
                                    breakpoints={{
                                        // For mobile devices
                                        320: { // Adjust this breakpoint to the minimum width you want to target
                                            slidesPerView: 1.2,
                                            spaceBetween: 10 // You can adjust this value as needed
                                        },
                                        // For tablets
                                        768: { // Adjust this breakpoint to the width you want to target for tablets
                                            slidesPerView: 3,
                                            spaceBetween: 20
                                        },
                                        // For desktops
                                        1024: {
                                            slidesPerView: 5,
                                            spaceBetween: 30
                                        }
                                    }}
                                >
                                    {scrollImages.length > 1 && (
                                        scrollImages !== null && (
                                            scrollImages.map((img, i) => (
                                                <div key={i}>
                                                    <SwiperSlide>
                                                        <div className="slide-content ion-text-center ion-padding-horizontal">
                                                            <div className="card" id="primary">
                                                                <IonImg src={img.src} alt="Product" className="product-detail-img" />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </div>
                                            ))
                                        )
                                    )}
                                </Swiper>
                            </div>
                            {booking.map((user, i) => {
                                return (
                                    <div key={i}>

                                        {expired === false && (
                                            <>
                                                {user.status == 3 && (
                                                    <>
                                                        <IonGrid className="btn-grid">
                                                            <IonRow className="btn-row">
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => setShowRejectModal(true)} className="reject-btn">
                                                                        Reject Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => { setShowModal(true) }} className="booking-details-btn">
                                                                        Accept Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                        <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="first-col" size="6" col-6>
                                                                                <IonLabel className="font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.from_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.to_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="sec-col">
                                                                            <IonLabel className="value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                                        <IonLabel className="value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })

                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow style={{ borderBottom: "1px solid #E6E6E6CC", borderRight: "1px solid #E6E6E6CC", borderLeft: "1px solid #E6E6E6CC", }}>
                                                                    <IonCol className="first-col" style={{ border: "none" }} size="6">
                                                                        <IonLabel className="font-color"><b>Notes From AFH</b></IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" style={{ border: "none" }} className="first-col">
                                                                        <IonLabel className="value-color"> </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="12">
                                                                        <div style={{
                                                                            overflow: "auto",
                                                                            overflowY: "scroll",
                                                                            height: "unset",
                                                                            background: "#f8f9f9",
                                                                            padding: "5px"
                                                                        }}>
                                                                            <IonLabel className="value-color">{user.partnernotes}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12" col-12>
                                                                    <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                        Go Back
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                                {user.status == 2 && (
                                                    <>
                                                        <IonItem lines="none" detail={false} button className='styleS' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Customer Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="first-col" size="5" col-5>
                                                                        <IonLabel className="font-color">Customer Name</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="7" col-7>
                                                                        <IonLabel className="value-color">{user.lead.firstname} {user.lead.lastname}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="5" col-5>
                                                                        <IonLabel className="font-color">Mobile Number</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="7" col-7>
                                                                        <IonLabel className="value-color">{user.lead.mobile}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="5" col-5>
                                                                        <IonLabel className="font-color">Email</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="7" col-7>
                                                                        <div>
                                                                            <IonLabel style={{ lineHeight: "1.5" }} className="value-color">{user.lead.email}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <IonItem lines="none" button detail={false} className="styleS" onClick={() => toggleAccordion(1)}>
                                                            <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(1) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="first-col" size="6" col-6>
                                                                                <IonLabel className="font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <div className="location-div">
                                                                            <img className="img-align compass-img" src="assets/imgs/icons/compass.svg" />
                                                                            <IonLabel className="location-text"
                                                                                onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}
                                                                            >
                                                                                Pickup Location
                                                                            </IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.from_address}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.from_ext_address?.map((address: any, index: 0) => (
                                                                        <React.Fragment key={index}>
                                                                            <IonCol className="first-col" size="6" col-6>
                                                                                <IonLabel className="font-color">{index + 1}. Extra Pickup Location</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{address}</IonLabel>
                                                                            </IonCol>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <div className="location-div">
                                                                            <img className="img-align compass-dp-lcn" src="assets/imgs/icons/compass.svg" />
                                                                            <IonLabel className="location-text"
                                                                                onClick={() => handleGoogleMapLinkTo(user.lead_details.to_postcode)}
                                                                            >
                                                                                Drop Location
                                                                            </IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.to_address}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="sec-col">
                                                                            <IonLabel className="value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_details.to_ext_address?.map((address: any, index: 0) => (
                                                                        <>
                                                                            <IonCol className="first-col" size="6" col-6 key={index}>
                                                                                <IonLabel className="font-color">{index + 1}. Extra Drop Location</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{address}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    ))}
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                                        <IonLabel className="value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })

                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow style={{ borderBottom: "1px solid #E6E6E6CC", borderRight: "1px solid #E6E6E6CC", borderLeft: "1px solid #E6E6E6CC", }}>
                                                                    <IonCol className="first-col" style={{ border: "none" }} size="6">
                                                                        <IonLabel className="font-color"><b>Notes From AFH</b></IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" style={{ border: "none" }} className="first-col">
                                                                        <IonLabel className="value-color"> </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="12">
                                                                        <div style={{
                                                                            overflow: "auto",
                                                                            overflowY: "scroll",
                                                                            height: "unset",
                                                                            background: "#f8f9f9",
                                                                            padding: "5px"
                                                                        }}>
                                                                            <IonLabel className="value-color">{user.partnernotes}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" className="pickup-btn" onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}>
                                                                        <IonIcon
                                                                            className="fav btn-icon"
                                                                            src="assets/imgs/icons/location-btn.svg"
                                                                            slot="end"
                                                                        /><span className="order-span">Pickup Location</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                                                        <IonButton expand="block" className="call-btn">
                                                                            <IonIcon
                                                                                className="fav btn-icon"
                                                                                src="assets/imgs/icons/call-btn.svg"
                                                                                slot="end"
                                                                            /><span className="order-span">Call Customer</span>
                                                                        </IonButton>
                                                                    </a>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                                {user.status == 4 && (
                                                    <>
                                                        <IonGrid className="btn-grid">
                                                            <IonRow className="btn-row">
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => setShowRejectModal(true)} className="reject-btn">
                                                                        Reject Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol size="6" col-6>
                                                                    <IonButton expand="block" onClick={() => { setShowModal(true) }} className="booking-details-btn">
                                                                        Accept Booking
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                        <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                            <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' />
                                                            <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                        </IonItem>
                                                        <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                            <IonGrid className='status3-card'>
                                                                <IonRow>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Service</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.category.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Product</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.product.title}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Event Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                    {user.lead_details.returndatetimedmY && (
                                                                        <>
                                                                            <IonCol className="first-col" size="6" col-6>
                                                                                <IonLabel className="font-color">Return Date & Time</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.from_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.to_postcode}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Customer Distance</IonLabel>
                                                                    </IonCol>
                                                                    {customerDistance &&
                                                                        <IonCol size="6" col-6 className="sec-col">
                                                                            <IonLabel className="value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                        </IonCol>
                                                                    }
                                                                    {user.lead_inputs && (
                                                                        user.lead_inputs.map((lead: any, i: any) => {
                                                                            return (
                                                                                <>
                                                                                    <IonCol className="first-col" size="6" col-6 key={i}>
                                                                                        <IonLabel className="font-color">{lead.input_property.title}</IonLabel>
                                                                                    </IonCol>
                                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                                        <IonLabel className="value-color">{lead.inputvalue}</IonLabel>
                                                                                    </IonCol>
                                                                                </>
                                                                            );
                                                                        })
                                                                    )}
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Trip Type</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6 className="sec-col">
                                                                        <IonLabel className="value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow style={{ borderBottom: "1px solid #E6E6E6CC", borderRight: "1px solid #E6E6E6CC", borderLeft: "1px solid #E6E6E6CC", }}>
                                                                    <IonCol className="first-col" style={{ border: "none" }} size="6">
                                                                        <IonLabel className="font-color"><b>Notes From AFH</b></IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" style={{ border: "none" }} className="first-col">
                                                                        <IonLabel className="value-color"> </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="12">
                                                                        <div style={{
                                                                            overflow: "auto",
                                                                            overflowY: "scroll",
                                                                            height: "unset",
                                                                            background: "#f8f9f9",
                                                                            padding: "5px"
                                                                        }}>
                                                                            <IonLabel className="value-color">{user.partnernotes}</IonLabel>
                                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                        <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                            <IonLabel className="payment-details">Payment Details</IonLabel>
                                                        </div>
                                                        <IonCard className="payment-details-card">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Your Price</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="font-color">Fee</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="p-details-label value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="p-details-r">
                                                                    <IonCol size="6" col-6>
                                                                        <IonLabel className="balance-pay">Balance to Collect from Customer</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="p-details-last-col" size="6" col-6>
                                                                        <IonLabel className="p-details-label balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCard>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12" col-12>
                                                                    <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                        Go Back
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {expired == true &&
                                            <>
                                                <IonItem lines="none" detail={false} button className='styleS' onClick={() => toggleAccordion(0)}>
                                                    <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' />
                                                    <IonLabel className="details-title" slot="start">Customer Details</IonLabel>
                                                </IonItem>
                                                <div className={`accordion-content ${isAccordionActive(0) ? 'show' : ''}`}>
                                                    <IonGrid className='status3-card'>
                                                        <IonRow>
                                                            <IonCol className="first-col" size="5" col-5>
                                                                <IonLabel className="font-color">Customer Name</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="7" col-7>
                                                                <IonLabel className="value-color">{user.lead.firstname} {user.lead.lastname}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="first-col" size="5" col-5>
                                                                <IonLabel className="font-color">Mobile Number</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="7" col-7>
                                                                <IonLabel className="value-color">{user.lead.mobile}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="first-col" size="5" col-5>
                                                                <IonLabel className="font-color">Email</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="7" col-7>
                                                                <div>
                                                                    <IonLabel style={{ lineHeight: "1.5" }} className="value-color">{user.lead.email}</IonLabel>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                                <IonItem lines="none" button detail={false} className="styleS" onClick={() => toggleAccordion(1)}>
                                                    <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' />
                                                    <IonLabel className="details-title" slot="start">Service Details</IonLabel>
                                                </IonItem>
                                                <div className={`accordion-content ${isAccordionActive(1) ? 'show' : ''}`}>
                                                    <IonGrid className='status3-card'>
                                                        <IonRow>
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel className="font-color">Service</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.product.category.title}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel className="font-color">Product</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.product.title}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel className="font-color">Event Date & Time</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.servicedatetimedmY}</IonLabel>
                                                            </IonCol>
                                                            {user.lead_details.returndatetimedmY && (
                                                                <>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">Return Date & Time</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{user.lead_details.returndatetimedmY}</IonLabel>
                                                                    </IonCol>
                                                                </>
                                                            )}
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel style={{ textDecoration: "none" }} className="location-text">
                                                                    Pickup Location
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.from_postcode}</IonLabel>
                                                            </IonCol>
                                                            {user.lead_details.from_ext_address?.map((address: any, index: 0) => (
                                                                <React.Fragment key={index}>
                                                                    <IonCol className="first-col" size="6" col-6>
                                                                        <IonLabel className="font-color">{index + 1}. Extra Pickup Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{address}</IonLabel>
                                                                    </IonCol>
                                                                </React.Fragment>
                                                            ))}
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel style={{ textDecoration: "none" }} className="location-text">
                                                                    Drop Location
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.to_postcode}</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel className="font-color">Customer Distance</IonLabel>
                                                            </IonCol>
                                                            {customerDistance &&
                                                                <IonCol size="6" col-6 className="sec-col">
                                                                    <IonLabel className="value-color">{customerDistance.distance_miles} Miles</IonLabel>
                                                                </IonCol>
                                                            }
                                                            {user.lead_details.to_ext_address?.map((address: any, index: 0) => (
                                                                <>
                                                                    <IonCol className="first-col" size="6" col-6 key={index}>
                                                                        <IonLabel className="font-color">{index + 1}. Extra Drop Location</IonLabel>
                                                                    </IonCol>
                                                                    <IonCol className="sec-col" size="6" col-6>
                                                                        <IonLabel className="value-color">{address}</IonLabel>
                                                                    </IonCol>
                                                                </>
                                                            ))}
                                                            {user.lead_inputs && (
                                                                user.lead_inputs.map((lead: any, i: any) => {
                                                                    return (
                                                                        <>
                                                                            <IonCol className="first-col" size="6" col-6 key={i}>
                                                                                <IonLabel className="font-color">{lead.input_property.title}</IonLabel>
                                                                            </IonCol>
                                                                            <IonCol className="sec-col" size="6" col-6>
                                                                                <IonLabel className="value-color">{lead.inputvalue}</IonLabel>
                                                                            </IonCol>
                                                                        </>
                                                                    );
                                                                })

                                                            )}
                                                            <IonCol className="first-col" size="6" col-6>
                                                                <IonLabel className="font-color">Trip Type</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="sec-col" size="6" col-6>
                                                                <IonLabel className="value-color">{user.lead_details.servicetype.title}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow style={{ borderBottom: "1px solid #E6E6E6CC", borderRight: "1px solid #E6E6E6CC", borderLeft: "1px solid #E6E6E6CC", }}>
                                                            <IonCol className="first-col" style={{ border: "none" }} size="6">
                                                                <IonLabel className="font-color"><b>Notes From AFH</b></IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" style={{ border: "none" }} className="first-col">
                                                                <IonLabel className="value-color"> </IonLabel>
                                                            </IonCol>
                                                            <IonCol size="12">
                                                                <div style={{
                                                                    overflow: "auto",
                                                                    overflowY: "scroll",
                                                                    height: "unset",
                                                                    background: "#f8f9f9",
                                                                    padding: "5px"
                                                                }}>
                                                                    <IonLabel className="value-color">{user.partnernotes}</IonLabel>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                                <div className="the-title p-details-title booking-title-top ion-padding-horizontal">
                                                    <IonLabel className="payment-details">Payment Details</IonLabel>
                                                </div>
                                                <IonCard className="payment-details-card">
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="font-color">Your Price</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="p-details-label value-color">£{(parseFloat(user.lead_quote_details.agencyfee) + parseFloat(user.lead_quote_details.partnerprice)).toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="font-color">Fee</IonLabel>
                                                            </IonCol>
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="p-details-label value-color">£{user.lead_quote_details.agencyfee.toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className="p-details-r">
                                                            <IonCol size="6" col-6>
                                                                <IonLabel className="balance-pay">Balance to Collect from Customer</IonLabel>
                                                            </IonCol>
                                                            <IonCol className="p-details-last-col" size="6" col-6>
                                                                <IonLabel className="p-details-label balance-pay">£{user.lead_quote_details.balance.toFixed(2)}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCard>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="12" col-12>
                                                            <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                                Go Back
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                            <IonModal id="example-modal" isOpen={showModal} backdropDismiss={false}>
                                <IonHeader className="ion-no-border">
                                    <IonToolbar className="fontName bgToolbar">
                                        <IonButtons slot="start">
                                        </IonButtons>
                                        <IonTitle className='fontName model-title ionTitle'>Booking ID: {localStorage.getItem('bookingId')}
                                            {bookingStatus == "3" &&
                                                <span className="popup-modified-chip">Unconfirmed</span>
                                            }
                                            {localStorage.getItem("confirmed") == "Confirmed" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "2" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "4" &&
                                                <span className="popup-modified-chip">Modified</span>
                                            }
                                        </IonTitle>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <div className="model-sub-div">
                                                    <IonLabel className='fontName model-sub-title'>Are you sure do you want confirm this booking?</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <input type="text" id="textAreaExample" value={confirmname}
                                                        onFocus={(e) => e.preventDefault()}
                                                        onChange={handleCnfmInput}
                                                        className="form-control-model" />
                                                    <label className="label-outline-model" htmlFor="textAreaExample">Your Name</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{cnfmNameErrorMessage}</div>
                                                {/* <IonLabel style={{ marginLeft: "0%" }} className='fontName inputTitle'>Enter Your Name</IonLabel>
                                    <IonToolbar className='InputIonToolbar'>
                                        <input
                                            className='cnfmModelInput fontName'
                                            placeholder='Name'
                                            value={confirmname}
                                            onFocus={(e) => e.preventDefault()}
                                            onChange={handleCnfmInput}
                                        ></input>
                                    </IonToolbar>
                                    <div className='fontName emailErrorMsg'>{cnfmNameErrorMessage}</div> */}
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div className='fontName model-sub-div'>
                                                    <IonLabel className="model-note">Note : Enter the name of the person confirming the booking</IonLabel>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow style={{ margin: "-4px 0px 7px 0px" }}>
                                            <IonCol size='12' col-12>
                                                <div className="checkbx-div">
                                                    <IonCheckbox
                                                        checked={isChecked}
                                                        className='fontName'
                                                        mode="ios"
                                                        onIonChange={handleCheckboxChange}
                                                    ></IonCheckbox>
                                                    <IonLabel className="checkbx-label">I agree with the <span className='fontName'><a className="checkbx-span" href='https://anythingforhire.com/terms'>Terms & Conditions</a></span></IonLabel>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeShowModal} className='fontName reject-btn' type='submit'>
                                                    Close
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={confirm} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Submit
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showConfirmedModal} id="example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/tick.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName b-cnfmed-title'>Booking Confirmed!</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName thanks-cnfmed-title'>Thank you for confirming the Booking ID: {localStorage.getItem('bookingId')}</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <IonButton style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowConfirmedModal(false)} expand="block">
                                                    Back to Booking
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal id="example-modal-reject" isOpen={showRejectModal} backdropDismiss={false}>
                                <IonHeader className="ion-no-border">
                                    <IonToolbar className="fontName bgToolbar">
                                        <IonButtons slot="start">
                                        </IonButtons>
                                        <IonTitle className='fontName model-title ionTitle'>Booking ID: {localStorage.getItem('bookingId')}
                                            {bookingStatus == "3" &&
                                                <span className="popup-modified-chip">Unconfirmed</span>
                                            }
                                            {localStorage.getItem("confirmed") == "Confirmed" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "2" &&
                                                <span className="popup-confirmed-chip">Confirmed</span>
                                            }
                                            {bookingStatus == "4" &&
                                                <span className="popup-modified-chip">Modified</span>
                                            }
                                        </IonTitle>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <div className="model-sub-div">
                                                    <IonLabel className='fontName model-sub-title'>Are you sure do you want reject this Booking?</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <IonSelect interface="popover" className='fontName form-control-model' placeholder="--select reason--" value={selectedValue} onIonChange={handleSelectChange}>
                                                        <IonSelectOption value="">Choose Option</IonSelectOption>
                                                        <IonSelectOption value="Wrong Price">Wrong Price</IonSelectOption>
                                                        <IonSelectOption value="No Vehicle">No Vehicle</IonSelectOption>
                                                    </IonSelect>
                                                    <label className="label-outline-model" htmlFor="textAreaExample">Reason for Rejection</label>
                                                </div>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div style={{ marginTop: "4%" }} className="model-container">
                                                    <textarea
                                                        placeholder="Type something here"
                                                        rows={4}
                                                        className='fontName form-control-model text-area-css'
                                                        value={rejecttextarea}
                                                        onChange={handlesetRejectTextAreaInput}
                                                    ></textarea>
                                                    <label className="label-outline-model" htmlFor="textAreaExample">Notes</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{textareaErrorMessage}</div>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <div className="checkbx-div">
                                                    <IonCheckbox
                                                        className='fontName'
                                                        mode="ios"
                                                        checked={isRejectChecked}
                                                        onIonChange={handleRejectCheckboxChange}
                                                    ></IonCheckbox>
                                                    <IonLabel className="checkbx-label">I am authorised for this booking to be rejected</IonLabel>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <div className="model-container">
                                                    <input
                                                        className='form-control-model fontName'
                                                        placeholder='Name'
                                                        value={rejectname}
                                                        onChange={handleRejectInput}
                                                    ></input>
                                                    <label className="label-outline-model" htmlFor="textAreaExample">Enter Your Name</label>
                                                </div>
                                                <div className='fontName emailErrorMsg'>{rejectNameErrorMessage}</div>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectModal} className='fontName reject-btn' type='submit'>
                                                    Close
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={handleOkayClick} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Submit
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showRejectCnfmModal} id="example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName b-cnfmed-title'>Do you want to Reject?</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName thanks-cnfmed-title'>Are you sure you want to reject the Booking ID: {localStorage.getItem('bookingId')}?</IonLabel>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectCnfmModal} className='fontName reject-btn' type='submit'>
                                                    Cancel
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size='6' col-6>
                                                <IonButton style={{ width: "100%", margin: "auto" }} onClick={reject} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                                                    Okay
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonModal isOpen={showRejectedModal} id="example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/cancel.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName b-cnfmed-title'>Booking Rejected!</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName thanks-cnfmed-title'>Booking ID: {localStorage.getItem('bookingId')} has been rejected as per your request.</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <Link style={{ textDecoration: "none" }} to={"/tabs/bookings"} onClick={() => setShowRejectedModal(false)}>
                                                    <IonButton style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' expand="block">
                                                        Back to Booking
                                                    </IonButton>
                                                </Link>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                            <IonLoading
                                isOpen={loading}
                                spinner="circular"
                                translucent={true}
                            />
                            <IonToast
                                isOpen={showToast}
                                onDidDismiss={() => setShowToast(false)}
                                message={errorMessage}
                                duration={3000}
                                color="danger"
                            />
                            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
                                <IonContent className="model-bg">
                                    <IonGrid className='cnfmModelGrid'>
                                        <IonRow>
                                            <IonCol size="12" col-12>
                                                <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                            </IonCol>
                                            <IonCol size="12" col-12>
                                                <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                            </IonCol>
                                            <IonCol size='12' col-12>
                                                <IonButton
                                                    routerLink="/login"
                                                    routerDirection="none"
                                                    style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn'
                                                    onClick={() => {
                                                        closeRejectCnfmModal();
                                                        closeShowModal();
                                                        setShowSuspendedModal(false);
                                                    }}
                                                    expand="block">
                                                    Back to Login
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonModal>
                        </div>
                    </IonContent>

                </>
            }
        </IonPage >
    );
};

export default Viewbooking;
