import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "./Addincome.css";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import apiConfig from "../../apiConfig";

import { Network } from '@capacitor/network';

interface OwnProps extends RouteComponentProps { }
const Addincome: React.FC<OwnProps> = () => {
  const history = useHistory();
  const darkModeValue = localStorage.getItem("darkMode");
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [product, setViewProduct] = useState<Array<any>>([]);
  const [sanitizedDescription, setSanitizedDescription] = useState('');

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const location = useLocation();
  const isHomePage = location.pathname;

  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useIonViewWillEnter(() => {
    if(localStorage.getItem("fcmToken") == "" || localStorage.getItem("fcmToken") == null || localStorage.getItem("fcmToken") == undefined) {
      history.push('/login');
    }
    setLoading(true);
    setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/viewproduct`, {
      "productid": localStorage.getItem("productId"),
      "token": localStorage.getItem("fcmToken"),
      // "partner_id": localStorage.getItem("partnerId")
    })
      .then(response => {
        if(response.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setViewProduct(response.data.data);
          setSanitizedDescription(response.data.data[0].description.replace(/\r\n/g, " "));
          setLoading(false);
          setBackdropVisible(false);
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
        console.log(error);
      });
  }, []);
  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);
  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center dark-ion-title">Product Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center dark-ion-title">Product Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="center-div">
            {product.map((user) => {
              return (
                <><div className="slider-holder animate__animated animate__fadeInRight">
                  {user.images === null && (
                    <IonImg src="assets/imgs/images/no-image.jpg" alt="Product" className="no-image-vd"></IonImg>
                  )}
                  <Swiper
                    modules={[Autoplay, Pagination]}
                    autoplay={true}
                    pagination={true}
                    spaceBetween={10} // Add some space between the slides
                    breakpoints={{
                        // For mobile devices
                        320: { // Adjust this breakpoint to the minimum width you want to target
                            slidesPerView: 1.2,
                            spaceBetween: 10 // You can adjust this value as needed
                        },
                        // For tablets
                        768: { // Adjust this breakpoint to the width you want to target for tablets
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        // For desktops
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 30
                        }
                    }}
                  >
                    {user.images !== null && (
                      user.images.map((img: any, i: any) => {
                        return (
                          <div key={i}>
                            <SwiperSlide>
                              <div className="slide-content ion-text-center ion-padding-horizontal">
                                <div className="card" id="primary">
                                  <IonImg src={img.src} alt="Product" className="product-detail-img"></IonImg>
                                </div>
                              </div>
                            </SwiperSlide>
                          </div>
                        );
                      })
                    )}
                  </Swiper>
                </div>
                  <div className="the-form">
                    <div className="detail-item">
                      <IonLabel style={{ color: "#CCC" }} className="dark-dash-main-titl prdt-dtls">Product Details</IonLabel>
                    </div>
                    <table className="dark-product-d-table">
                      <tbody>
                        <tr>
                          <td><h6 className="dark-font-color">Service Name</h6></td>
                          <td><h6 className="dark-value-color">{user.title}</h6></td>
                        </tr>
                        {user.category != null && (
                          <tr>
                            <td><h6 className="dark-font-color">Category</h6></td>
                            <td><h6 className="dark-value-color">{user.category.title}</h6></td>
                          </tr>
                        )}
                        {user.brand != null && (
                          <tr>
                            <td><h6 className="dark-font-color">Brand</h6></td>
                            <td><h6 className="dark-value-color">{user.brand.title}</h6></td>
                          </tr>
                        )}
                        <tr>
                          <td><h6 className="dark-font-color">Description</h6></td>
                          <td><h6 className="dark-value-color">{sanitizedDescription}</h6></td>
                        </tr>
                        {user.vat != null && (
                          <tr>
                            <td><h6 className="dark-font-color">Vatable</h6></td>
                            <td>
                              {user.vat == 0 && (
                                <h6 className='textColor dark-value-color'>No</h6>
                              )}
                              {user.vat == 1 && (
                                <h6 className='textColor dark-value-color'>Yes</h6>
                              )}
                              {user.vat == 2 && (
                                <h6 className='textColor dark-value-color'>No</h6>
                              )}
                            </td>
                          </tr>
                        )}
                        {user.pricingdetail != null && (
                          <tr>
                            <td><h6 className="dark-font-color">Free Miles</h6></td>
                            <td><h6 className="dark-value-color">{user.pricingdetail.freemiles}</h6></td>
                          </tr>
                        )}
                        {user.pricingdetail != null && (
                          <tr>
                            <td><h6 className="dark-font-color">Maximum Coverage</h6></td>
                            <td><h6 className="dark-value-color">{user.pricingdetail.maxcoverage}</h6></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div></>
              );
            })}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }

      {networkStatus == false && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="addincome-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">Product Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="addincome">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="addincome-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">Product Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="addincome">
          <div className="center-div">
            {product.map((user) => {
              return (
                <><div className="slider-holder animate__animated animate__fadeInRight">
                  {user.images === null && (
                    <IonImg src="assets/imgs/images/no-image.jpg" alt="Product" className="no-image-vd"></IonImg>
                  )}
                  <Swiper
                    modules={[Autoplay, Pagination]}
                    autoplay={true}
                    pagination={true}
                    spaceBetween={10} // Add some space between the slides
                                breakpoints={{
                                    // For mobile devices
                                    320: { // Adjust this breakpoint to the minimum width you want to target
                                        slidesPerView: 1.2,
                                        spaceBetween: 10 // You can adjust this value as needed
                                    },
                                    // For tablets
                                    768: { // Adjust this breakpoint to the width you want to target for tablets
                                        slidesPerView: 3,
                                        spaceBetween: 20
                                    },
                                    // For desktops
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 30
                                    }
                                }}
                  >
                    {user.images !== null && (
                      user.images.map((img: any, i: any) => {
                        return (
                          <div key={i}>
                            <SwiperSlide>
                              <div className="slide-content ion-text-center ion-padding-horizontal">
                                <div className="card" id="primary">
                                  <IonImg src={img.src} alt="Product" className="product-detail-img"></IonImg>
                                </div>
                              </div>
                            </SwiperSlide>
                          </div>
                        );
                      })
                    )}
                  </Swiper>
                </div><div className="the-form">
                    <div className="detail-item">
                      <IonLabel className="prdt-dtls">Product Details</IonLabel>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td><h6 className="font-color">Service Name</h6></td>
                          <td><h6 className="value-color">{user.title}</h6></td>
                        </tr>
                        {user.category != null && (
                          <tr>
                            <td><h6 className="font-color">Category</h6></td>
                            <td><h6 className="value-color">{user.category.title}</h6></td>
                          </tr>
                        )}
                        {user.brand != null && (
                          <tr>
                            <td><h6 className="font-color">Brand</h6></td>
                            <td><h6 className="value-color">{user.brand.title}</h6></td>
                          </tr>
                        )}
                        <tr>
                          <td><h6 className="font-color">Description</h6></td>
                          <td><h6 className="value-color">{sanitizedDescription}</h6></td>
                        </tr>
                        {user.vat != null && (
                          <tr>
                            <td><h6 className="font-color">Vatable</h6></td>
                            <td>
                              {user.vat == 0 && (
                                <h6 className='textColor value-color'>No</h6>
                              )}
                              {user.vat == 1 && (
                                <h6 className='textColor value-color'>Yes</h6>
                              )}
                              {user.vat == 2 && (
                                <h6 className='textColor value-color'>No</h6>
                              )}
                            </td>
                          </tr>
                        )}
                        {user.pricingdetail != null && (
                          <tr>
                            <td><h6 className="font-color">Free Miles</h6></td>
                            <td><h6 className="value-color">{user.pricingdetail.freemiles}</h6></td>
                          </tr>
                        )}
                        {user.pricingdetail != null && (
                          <tr>
                            <td><h6 className="font-color">Maximum Coverage</h6></td>
                            <td><h6 className="value-color">{user.pricingdetail.maxcoverage}</h6></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div></>
              );
            })}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Addincome;
