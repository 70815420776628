import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonImg,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import fingerprintService from "../../fingerprint.service";
import "./Getstarted.css";
import { PushNotifications } from '@capacitor/push-notifications';

import { Network } from '@capacitor/network';

import messaging from '../../Firebase';
import { getToken, isSupported } from 'firebase/messaging';
import OldFooter from "../../components/OldFooter";

const Getstarted: React.FC = () => {
    // const [loading, setLoading] = useState(false);
    // const [backdropVisible, setBackdropVisible] = useState(false);
    const darkModeValue = localStorage.getItem("darkMode");
    const newUser = localStorage.getItem("newUser");

    const history = useHistory();

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');
    const [notificationTap, setNotificationTap] = useState('');
    const [bookingId, setBookingId] = useState('');

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);
    const [networkStatus, setNetworkStatus] = useState<any>(true);

    function getPlatforms() {
        // Simple check for desktop or mobile platform
        if (navigator.userAgent.indexOf('Mobi') > -1) {
            return "mobile";
        } else {
            return "desktop";
        }
    }

    function getMobileBrowser() {
        // Check if the device is mobile
        if (navigator.userAgent.indexOf('Mobi') > -1) {
            // Check for mobile browsers
            if (/android/i.test(navigator.userAgent)) {
                return "Android Browser";
            } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return "Safari (iOS)";
            } else if (/Windows Phone/i.test(navigator.userAgent)) {
                return "Windows Phone Browser";
            } else {
                return "Mobile Browser";
            }
        } else {
            return "Not a mobile browser";
        }
    }

    useEffect(() => {
        console.log("getMobileBrowser()",getMobileBrowser());

        if(getMobileBrowser() == "Safari (iOS)" || getMobileBrowser() == "Android Browser" || getMobileBrowser() == "Windows Phone Browser" || getMobileBrowser() == "Mobile Browser"){
            handShake("cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
            localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
            setTimeout(() => {
                history.push('/login');
            }, 5000);
        }

        // This is FCM vapidKey        
        // BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI

        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
        });

        // for pwa only

        if (getPlatforms() == "desktop") {
            isSupported().then((supported) => {
                console.log('Messaging support check:', supported);
                if (supported) {
                    try {
                        console.log('Firebase Messaging initialized:', messaging);
                        // Request permission to send notifications
                        Notification.requestPermission().then(permission => {
                            if (permission === "granted") {

                                getToken(messaging, { vapidKey: 'BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI' })
                                .then((token) => {
                                    console.log('Token generated:', token);
                                    if(token){
                                    handShake(token);
                                    localStorage.setItem("fcmToken", token);
                                    }
                                })
                                .catch((error) => {
                                    console.error('Token generation failed:', error);
                                    handShake("cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                                    localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                                });
                            } else {
                                console.error("Permission denied for notifications");
                                handShake("cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                                localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                            }
                        });
                    } catch (error) {
                        console.error('Failed to initialize Firebase Messaging:', error);
                        handShake("cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                        localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                    }
                }
            }).catch((error) => {
                console.error('Error during Firebase Messaging support check:', error);
                handShake("cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
            });
        } else {


            PushNotifications.register();
            const handlePushNotification = (notification: any) => {
                // Access the notification payload, including the image URL
                const { title, body, data } = notification;

                // Check if the notification contains an image URL
                const imageUrl = data?.image || null;

                // Handle the image display and notification content
                if (imageUrl) {
                    // Image is present, handle image display (e.g., using an image component)
                    console.log('Image URL:', imageUrl);
                    // Implement your image display logic here, e.g., with an <img> element
                } else {
                    // Image is not present, handle notification content without an image
                    console.log('No Image URL provided');
                }

                // Handle other notification data or actions as needed
                console.log('Received push notification:', title, body, data);
            };

            // Add the pushNotificationReceived listener
            const pushNotificationReceivedListener = PushNotifications.addListener(
                'pushNotificationReceived',
                handlePushNotification
            );
            addListeners();
            // Return a cleanup function to remove the listener when the component unmounts
            return () => {
                pushNotificationReceivedListener.remove();
            };
        }

    }, []);


    // const checkPartner = () => {
    //     axios.post(`${apiConfig.baseUrl}/checkpartner`, {
    //         "bookingId": "214361",
    //         "token": localStorage.getItem("fcmToken"),
    //     })
    //         .then(response => {
    //             console.log("response", response);
    //             if (response.data.suspendstatus == false) {
    //                 setSupendedMsg(response.data.message);
    //                 setSupendedTitle(response.data.title);
    //                 setShowSuspendedModal(true);
    //             } else {

    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // const notificationRoute = () => {
    //     console.log("localStorage.getItem---------->>>", localStorage.getItem("bookingId"));
    //     axios.post(`${apiConfig.baseUrl}/ViewBookingList`, {
    //         "bookingId": localStorage.getItem("bookingId"),
    //         "token": localStorage.getItem("fcmToken"),
    //         "status": "2"
    //     })
    //         .then(response => {
    //             if (response.data.suspendstatus == false) {
    //                 setSupendedMsg(response.data.message);
    //                 setSupendedTitle(response.data.title);
    //                 setShowSuspendedModal(true);
    //             } else {
    //                 if (response.data.data[0].status == 8 || response.data.data[0].status == 5 || response.data.data[0].status == 7 || response.data.data[0].status == 1 || response.data.data[0].lead_quote_details == null) {
    //                     setNotificationRoute('wrong');
    //                     console.log('----------->>>>Wrong route');
    //                 } else {
    //                     setNotificationRoute('correct');
    //                     console.log('----------->>>Correct route');
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
            console.info('Registration token: ', token.value);
            localStorage.setItem("fcmToken", token.value);
        })

        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });
        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification.body);
            const notifyBody = notification.body;
            const idMatch = notifyBody?.match(/ID: #(\d+)/);
            if (idMatch) {
                const id = idMatch[1]; // Get the first capturing group
                console.log('Extracted ID: ', id);

                // Debugging statements to check the flow
                localStorage.setItem("bookingId", id);
                console.log('localStorage updated with ID: ', localStorage.getItem("bookingId"));

                setBookingId(id);
                console.log('Booking ID set: ', id);

                // notificationRoute();
            } else {
                console.log('ID not found in the notification body.');
                localStorage.setItem("bookingId", "");
            }
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification);
            if (notification.actionId === "tap") {
                setNotificationTap(notification.actionId);
                if (!bookingId) {
                    // if (notifyRoute === 'correct') {
                    // history.push('/viewbooking');
                    //     console.log("correct viewbooking --------->>>");
                    // } else {
                    //     console.log("wrong notifications --------->>>");
                    history.push('/notifications');
                    // }
                } else {
                    console.log("avail id notifications --------->>>");
                    history.push('/viewbooking');
                }
            }
        });

    }

    useIonViewWillEnter(() => {
        // firebase.initializeApp(firebaseConfig);
        // async function fetchToken() {
        //     try {
        //         const { token } = await FCM.getToken();
        //         console.log("FCM Token -------->>>>", token);
        //         localStorage.setItem("fcmToken", token);
        //         if (localStorage.getItem("fcmToken") != "") {
        //             handShake();
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        // fetchToken();
        if (newUser == "") {
            localStorage.setItem("newUser", "no");
        } else {
            localStorage.setItem("newUser", "yes");
        }
        if (darkModeValue == "darkMode") {
            localStorage.setItem("darkMode", "darkMode");
        } else if (darkModeValue == "") {
            localStorage.setItem("darkMode", "lightMode");
        } else {
            localStorage.setItem("darkMode", "lightMode");
        }
        // localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
        
        // checkPartner();
    }, []);
   const handShake = ( token:any ) => {
    // Retrieve the FCM token from localStorage
    const fcmToken = token;

    if (!fcmToken) {
        console.error("No FCM token found in localStorage.");
        return;
    }

    axios.post(`${apiConfig.baseUrl}/handshake`, { token: fcmToken })
        .then((response) => {
            console.log("Handshake response:", response.data);

            if (response.data.suspendstatus === false) {
                setSupendedMsg(response.data.message);
                setSupendedTitle(response.data.title);
                setShowSuspendedModal(true);
            } else {
                if (newUser === "yes") {
                    if (response.data.status === false || response.data.data.remember_me === 0) {
                        if(getPlatforms() !== "desktop"){
                            setTimeout(() => {
                                history.push('/login');
                            }, 5000);
                        }
                    } else {
                        // Store user details in localStorage
                        localStorage.setItem("name", response.data.data.name);
                        localStorage.setItem("tradingName", response.data.data.ownername);
                        localStorage.setItem("email", response.data.data.email);
                        localStorage.setItem("mobile", response.data.data.mobile);
                        localStorage.setItem("postcode", response.data.data.postcode);

                        // Unlock app with fingerprint authentication
                        unlockAppWithFingerprint();
                    }
                } else {
                    if(getPlatforms() !== "desktop"){
                    history.push('/intro');
                    }
                }
            }
        })
        .catch((error) => {
            console.error("Handshake API error:", error);

            // Handle network error specifically
            if (error.code === "ERR_NETWORK") {
                setNetworkStatus(false);
            } else {
                setNetworkStatus(true);
            }
        });
};


    async function unlockAppWithFingerprint() {
        try {
            await fingerprintService.authenticate()
                .then((result_1: any) => {
                    console.log('Inside success', result_1);
                    if (notificationTap === 'tap') {
                        if (!bookingId) {
                            // if (notifyRoute === 'correct') {
                            // history.push('/viewbooking');
                            //     console.log("correct viewbooking --------->>>");
                            // } else {
                            //     console.log("wrong notifications --------->>>");
                            history.push('/notifications');
                            // }
                        } else {
                            console.log("avail id notifications --------->>>");
                            history.push('/viewbooking');
                        }
                    } else {
                        history.push('/tabs/dashboard');
                    }
                })
                .catch((error: any) => {
                    console.log("error--->>Fingerprint", error);
                    if (error) {
                        if(getPlatforms() !== "desktop"){
                            setTimeout(() => {
                                history.push('/login');
                            }, 5000);
                            }
                    }
                    // Handle the error
                });
        } catch (error) {
            console.error('Error:', error);
            // Handle any errors that occurred during authentication
        }
    }
    return (
        <IonPage>
            {networkStatus == false &&
                <div className="no-network-div">
                    <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                    <h6 className='fontName'>Check your internet connection and try again</h6>
                </div>
            }
            {networkStatus == true && 
             getPlatforms() == "desktop" && (
                <IonHeader>
                    <IonToolbar className="lnd-header">
                        <div className="lnd-flx">
                        <IonImg className="lnd-header-img" src="assets/imgs/images/afh-logo-landscape-white.svg"></IonImg>
                        <div className="lnd-btns-div">
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/sign-up"
                        routerDirection="none"
                        style={{ marginRight: "5%" }}
                        >Join us</IonButton>
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/login"
                        routerDirection="none">Login</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
    )}
                <IonContent className="ion-padding overflow background-img" fullscreen>
                    {getPlatforms() == "desktop" ? (
                         <><div className="animated-background">
                        <div className="content">
                            <h1 className="lnd-banner-title">Join Anything For Hire today</h1>
                            <IonButton className="lnd-banner-btn"
                            routerLink="/sign-up"
                            routerDirection="none">Submit Your Hire Company</IonButton>
                            <IonButton className="lnd-banner-btn" 
                            routerLink="/login"
                            routerDirection="none">Login</IonButton>
                        </div>
                    </div>
                    <IonGrid className="lnd-second-grid">
                            <IonRow className="lnd-mrgn-row">
                                <IonCol size="12">
                                <IonTitle className="market-titile">THE MARKETPLACE FOR HIRE COMPANIES IN THE UK</IonTitle>
                               </IonCol>
                                <IonCol size="6">
                                <div className="video-container">
                                    <iframe
                                        width="100%"
                                        height="283"
                                        src="https://www.youtube.com/embed/MM5x8Wa-EsA"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                <div className="video-text-details">
                                    <p>
                                    Anything For Hire marketplace connects customers with local hire companies. Allowing them to compare and hire services all from one convenient place.
                                    </p>
                                    <p>
                                    All prices are controlled by the hire company using the Anything For Hire partner centre, thereby allowing hire companies to be in control of their business. Anything For Hire doesn't charge companies any subscription fees, upfront costs or cost per lead.
                                    </p>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid>
                            <IonRow className="lnd-mrgn-row">
                                <IonCol className="lnd-bsn-col" size="6">
                                <div className="phones-header">
                 <h2 className="lnd-bsn-title">Manage your business from one convenient place</h2>
                 <p className="red-block-container-p">Using our simple search, you can ask us to hire any service or item you want!</p>
                 <p className="red-block-container-p">From limos to tool hire, we strive to find you the best prices from hundreds of local services.</p>
               </div>
               <div className="lnd-store-btns-div">
               <a href='https://itunes.apple.com/gb/app/anything-for-hire-partner/id1440776168?mt=8' target="_blank" title="Download Our Partner App for iOS">
                            <img
                                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                alt="Apple App Store"
                                className="store-button" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.afhapp.afhpartner" target="_blank" title="Download Our Partner App for Android">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Google Play Store"
                                className="ftr-play-btn store-button" />
                                </a>
                        </div>
                                </IonCol>
                                <IonCol size="6">
                                <div className="phones-container partners-phone-container">
                                    <IonImg
                                        src="https://partner.anythingforhire.com/assets/images/afh-iphone.png"
                                        alt="Ios App mock up of Anything for hire"
                                    />
                                    <IonImg
                                    style={{ 
                                        width: "50%",
                                        marginLeft: "3%"
                                    }}
                                        src="https://partner.anythingforhire.com/assets/images/afh-android.png"
                                        alt="Android App up of Anything for hire"
                                    />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid className="lnd-icon-grid">
                            <IonRow className="lnd-mrgn-row">
                                <IonCol size="12">
                                    <IonTitle className="lnd-main-title">Why should I sign up with Anything for Hire?</IonTitle>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/best.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">One of the best online directories in the UK!</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">Customers come to AFH because they know we'll be able to offer any service they need, don't miss out on customers by not being a partner!</p>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/booking.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">All your AFH bookings on a beautiful platform</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">We've built an incredible platform for you to manage your bookings from online, so what are you waiting for?</p>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/payment.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">Make money!</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">AFH looks to help you make more money by giving you an additional revenue stream!</p>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/trust.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">Trusted Suppliers</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">All of our providers for our items and services are vetted and verified so that you can have confidence your business' reputation is protected.</p>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/free.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">FREE BOOKING SOFTWARE</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">Our Partner Centre software is free to use. It even links to your website without any technical knowledge.</p>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="lnd-icon-div">
                                        <IonImg className="lnd-icon" src="assets/imgs/icons/wordpress.svg"></IonImg>
                                        <IonLabel className="lnd-icon-label">CONNECT WITH OUR WORDPRESS PLUGIN</IonLabel>
                                    </div>
                                    <p className="lnd-icon-p">Grow your hire business even more. Install the <a href="https://wordpress.org/plugins/anything-for-hire/" title="Wordpress Plugin" target="_blank" rel="nofollow noreferrer noopener" style={{color: "#464242"}}>Anything for Hire Wordpress Plugin</a> within 2 mins.</p>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <OldFooter />
                        </>
                    ) : (
                        <>
                        <div className="fontName background-img-container">
                                <img src="assets/imgs/images/setStartedBg.png" alt="Background Image" />
                            </div><IonGrid>
                                    <IonRow>
                                        <IonCol col-12 size="12">
                                            <IonImg className="afh-logo" src="assets/imgs/images/afhLogo.svg" alt="Logo Image"></IonImg>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid><div className="m-title getstarted-title">
                                    <IonLabel className="getstarted-msg">Welcome to Anything for Hire Partner Centre.</IonLabel>
                                    <br />
                                    <IonLabel className="letsgrow-msg">Manage your bookings and services on the go!</IonLabel>
                                </div>
                                </>
                    )}
                    <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
                        <IonContent className="model-bg">
                            <IonGrid className='cnfmModelGrid'>
                                <IonRow>
                                    <IonCol size="12" col-12>
                                        <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                    </IonCol>
                                    <IonCol size='12' col-12>
                                        <IonButton
                                            routerLink="/login"
                                            routerDirection="none"
                                            style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                                            Back to Login
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    </IonModal>
                </IonContent>
            
        </IonPage>
    );
};

export default Getstarted;
