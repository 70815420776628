import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Capacitor } from "@capacitor/core";
import { Device } from '@awesome-cordova-plugins/device';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { Network } from '@capacitor/network';

import "./Addproduct.css";
import { useLocation } from 'react-router-dom';
import apiConfig from "../../apiConfig";
import axios from "axios";

interface OwnProps extends RouteComponentProps { }
const Addproduct: React.FC<OwnProps> = ({ history }) => {
    const darkModeValue = localStorage.getItem("darkMode");
    type LocationState = {
        id: string; // or whatever the type of the id is
    }
    const validInput = /^[A-Za-z0-9 \n]*$/;

    const location = useLocation<LocationState>();
    const productId = location.state?.id;
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [product, setViewProduct] = useState<Array<any>>([]);
    const [sanitizedDescription, setSanitizedDescription] = useState('');
    const capturedImagesRef = useRef<(File | string)[]>([]);
    const [capturedImages, setCapturedImages] = useState<File[]>([]);
    const [title, setTitle] = useState("");
    const latestInputDescriptionValue = useRef('');
    const latestInputProductNameValue = useRef('');
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [desErrorMessage, setDesErrorMessage] = useState('');
    const [description, setDescription] = useState("");

    const [showToasSuccess, setShowToastSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    const [networkStatus, setNetworkStatus] = useState<any>(true);

    useEffect(() => {
        if(localStorage.getItem("fcmToken") == "" || localStorage.getItem("fcmToken") == null || localStorage.getItem("fcmToken") == undefined) {
            history.push('/login');
          }
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
        });
        checkAndroidVersion();
    }, []);
    const goBack = () => {
        history.goBack();
    };
    const checkAndroidVersion = () => {
        console.log('Device platform:', Device.platform);
        console.log('Device version:', Device.version);
    };
    const handlePictureUpload = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;

        input.onchange = async (event) => {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
                const files = Array.from(target.files);
                const updatedImages = [...capturedImages]; // Use capturedImages from state

                setLoading(true);
                setBackdropVisible(true);

                try {
                    const uploadedFiles = [];
                    const uploadedDataUrls = [];
                    for (const file of files) {
                        const blob = await file.arrayBuffer().then(data => new Blob([data]));
                        const image = await createImageBitmap(blob);

                        const canvas = document.createElement('canvas');
                        // Resize image here:
                        canvas.width = image.width > 1000 ? 1000 : image.width;
                        canvas.height = image.width > 1000 ? Math.floor((image.height * 1000) / image.width) : image.height;

                        const ctx = canvas.getContext('2d');
                        if (!ctx) {
                            console.error('Unable to get 2D context');
                            return null;
                        }
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                        const optimizedBlob = await new Promise<Blob | null>((resolve) =>
                            canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
                        );

                        if (!optimizedBlob) {
                            console.error('Unable to create blob from canvas');
                            return null;
                        }

                        const reader = new FileReader();
                        const dataUrlPromise = new Promise<string>((resolve) => {
                            reader.onloadend = () => resolve(reader.result as string);
                        });
                        reader.readAsDataURL(optimizedBlob);

                        const dataUrl = await dataUrlPromise;

                        const timestamp = new Date().getTime();
                        const randomString = Math.random().toString(36).substring(2, 8);
                        const fileName = `image_${timestamp}_${randomString}.jpg`;

                        await Filesystem.writeFile({
                            path: fileName,
                            data: dataUrl,
                            directory: Directory.Documents,
                            recursive: true,
                        });

                        const fileObj = new File([optimizedBlob], fileName, { type: 'image/jpeg' });
                        uploadedFiles.push(fileObj);
                        uploadedDataUrls.push(dataUrl);
                    }

                    const newImages = [...updatedImages, ...uploadedFiles];
                    setCapturedImages(newImages); // Update state directly

                } catch (error) {
                    console.error('Error uploading images:', error);
                } finally {
                    setLoading(false);
                    setBackdropVisible(false);
                }
            }
        };

        input.click();
    };
    const backToServices = () => {
        setTitle('');
        setDescription('');
        latestInputProductNameValue.current = "";
        latestInputDescriptionValue.current = "";
        capturedImagesRef.current = [];
        setCapturedImages([]);
    }
    const handlePictureCapture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
        });
        setLoading(true);
        setBackdropVisible(true);
        const imageUrl = Capacitor.convertFileSrc(image.webPath!);
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Convert blob to ImageBitmap
        const img = await createImageBitmap(blob);

        // Create a canvas and resize the image
        const canvas = document.createElement('canvas');
        canvas.width = 800;
        canvas.height = 800 * (img.height / img.width);

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error('Unable to get 2D context');
            return;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas image back to a blob
        const resizedBlob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
        );

        if (!resizedBlob) {
            console.error('Unable to create blob from canvas');
            return;
        }

        const timestamp = new Date().getTime();
        const randomString = Math.random().toString(36).substring(2, 8);
        const fileName = `image_${timestamp}_${randomString}.jpg`;
        const file = new File([resizedBlob], fileName, { type: resizedBlob.type, lastModified: new Date().getTime() });
        const updatedImages = [...capturedImages, file];
        console.log("updatedImages", updatedImages);
        setCapturedImages(updatedImages);
        setLoading(false);
        setBackdropVisible(false);
    };

    const handleTitleInput = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue === "") {
            setTitle(inputValue);
            latestInputProductNameValue.current = inputValue;
            setTitleErrorMessage("");
        } else if (inputValue.startsWith(' ')) {
            setTitleErrorMessage("Input cannot start with a space.");
        } else if (!validInput.test(inputValue)) {
            setTitleErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
        } else {
            setTitle(inputValue);
            latestInputProductNameValue.current = inputValue;
            setTitleErrorMessage("");
        }
    }
    const handleDesInput = (event: any) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            setDescription(inputValue);
            latestInputDescriptionValue.current = inputValue;
            setDesErrorMessage("");
        } else if (inputValue.startsWith(' ')) {
            setDesErrorMessage("Input cannot start with a space.");
        } else
        //  if (!validInput.test(inputValue)) {
        //     setDesErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
        // } else 
        {
            setDescription(inputValue);
            latestInputDescriptionValue.current = inputValue;
            setDesErrorMessage("");
        }
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!latestInputProductNameValue.current || !latestInputDescriptionValue.current || capturedImages.length == 0) {
            setErrorMessage("Please enter all the fields and upload picture.");
            setShowToast(true);
        } else {
            setLoading(true);
            setBackdropVisible(true);
            const formData = new FormData();

            formData.append('title', latestInputProductNameValue.current);
            const token = localStorage.getItem("fcmToken") || "";
            formData.append('token', token);
            // const partner_id = localStorage.getItem("partnerId") || "";
            // formData.append('partner_id', partner_id);
            formData.append('description', latestInputDescriptionValue.current);
            capturedImages.forEach((file, index) => {
                formData.append(`images[${index}]`, file);
            });

            try {
                const response = await axios.post(`${apiConfig.baseUrl}/addproduct`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if(response.data.message == "User not found") {
                    setLoading(false);
                    setBackdropVisible(false);
                    history.push('/login');
                  } else {
                if (response.data.suspendstatus == false) {
                    setSupendedMsg(response.data.message);
                    setSupendedTitle(response.data.title);
                    setShowSuspendedModal(true);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    if (response.data.status == true) {
                        formData.delete('title');
                        formData.delete('token');
                        latestInputProductNameValue.current = "";
                        latestInputDescriptionValue.current = "";
                        capturedImagesRef.current = [];
                        formData.delete('description');
                        capturedImages.forEach((_, index) => {
                            formData.delete(`images[${index}]`);
                        });
                        setSuccessMessage(response.data.message);
                        setTitle('');
                        setDescription('');
                        setCapturedImages([]);
                        setLoading(false);
                        setBackdropVisible(false);
                        setShowToastSuccess(true);
                        history.push("/tabs/services");

                    } else {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage(response.data.message);
                        setShowToast(true);
                    }
                }
            }
                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            } catch (error) {
                setLoading(false);
                setBackdropVisible(false);
                console.error('Error submitting form:', error);
            }
        }
    };
    const suspendedModalClose = () => {
        setTitle('');
        setDescription('');
        latestInputProductNameValue.current = "";
        latestInputDescriptionValue.current = "";
        capturedImagesRef.current = [];
        setCapturedImages([]);
        setShowSuspendedModal(false)
    }
    return (
        <IonPage>
            {networkStatus == false && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="dark-mode-header-toolbar center-div">
                            <IonButtons slot="start" onClick={backToServices}>
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBackArrow.svg"
                                        slot="start" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-center dark-ion-title">Product Information</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="dark-mode-ion-content">
                        <div className="no-network-div center-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true &&
                darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="dark-mode-header-toolbar center-div">
                            <IonButtons slot="start" onClick={backToServices}>
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBackArrow.svg"
                                        slot="start" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-center dark-ion-title">Product Information</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/darkBell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="dark-mode-ion-content">
                        <div className="the-form mt-2 center-div">
                            <div className="m-title">
                                <IonLabel className="dark-main-title">Add Your Product Details</IonLabel>
                            </div>
                            <div className="input-container">
                                <input type="text"
                                    id="textAreaExample"
                                    value={title}
                                    onChange={handleTitleInput}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter')
                                            e.preventDefault();
                                    }}
                                    className="dark-form-control" />
                                <label className="dark-label-outline" htmlFor="textAreaExample">Product Title</label>
                            </div>
                            <div className='fontName emailErrorMsg'>{titleErrorMessage}</div>
                            <div className="input-container">
                                <textarea id="textAreaExample"
                                    rows={4}
                                    value={description}
                                    onChange={handleDesInput}
                                    className="dark-form-control text-area-focus">
                                </textarea>
                                <label className="dark-label-outline" htmlFor="textAreaExample">Description</label>
                            </div>
                            <div className='fontName emailErrorMsg'>{desErrorMessage}</div>

                            <div className="m-title">
                                <IonLabel className="dark-main-title">Add Your Product Image</IonLabel>
                            </div>
                            <div className="product-image-add send-money-holder animate__animated animate__fadeInUp animate__slow">
                                <IonGrid>
                                    <IonRow>
                                        {Device.platform == "iOS" && (
                                            <IonCol size="6" col-6>
                                                <div className="dark-contact-holder mt-0" onClick={handlePictureUpload}>
                                                    <div className="dark-add-product ion-text-center">
                                                        <div className="add-btn-holder">
                                                            <img className="plus-btn-icon" src="assets/imgs/icons/plus.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonCol>
                                        )}
                                        {Device.version < "13" && (
                                            <IonCol size-md="3" size="6">
                                                <div className="dark-contact-holder mt-0" onClick={handlePictureUpload}>
                                                    <div className="dark-add-product ion-text-center">
                                                        <div className="add-btn-holder">
                                                            <img className="plus-btn-icon" src="assets/imgs/icons/plus.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonCol>
                                        )}
                                        <IonCol size-md="3" size="6" onClick={handlePictureCapture} className="capture-option">
                                            <div className="dark-contact-holder mt-0">
                                                <div className="dark-add-product ion-text-center">
                                                    <div>
                                                        <img className="camera-btn-icon" src="assets/imgs/icons/darkCamera.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCol>
                                        {capturedImages.map((image, index) => {
                                            return (
                                                <IonCol key={index} size-md="3" size='6'>
                                                    <div className="fontName image-container-add">
                                                        <IonImg className="fontName addProductImg" src={URL.createObjectURL(image)} alt={`Image ${index}`} />
                                                    </div>
                                                </IonCol>
                                            );
                                        })}
                                    </IonRow>
                                </IonGrid>
                                <IonGrid className="add-product-mt m-d-none">
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonButton expand="block" className="pickup-btn footer-btn" onClick={handleSubmit}>
                                                Add Product
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>

                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true} />
                        <IonToast
                            isOpen={showToast}
                            onDidDismiss={() => setShowToast(false)}
                            message={errorMessage}
                            duration={3000}
                            color="danger" />
                        <IonToast
                            isOpen={showToasSuccess}
                            onDidDismiss={() => setShowToast(false)}
                            message={successMessage}
                            duration={3000}
                            color="success" />
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true} />
                        <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                            <IonContent className="dark-model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }}
                                                className='fontName booking-details-btn'
                                                onClick={suspendedModalClose}
                                                expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                    </IonContent>
                    <IonFooter className="ion-no-border d-d-none">
                        <IonToolbar className="dark-mode-header-toolbar center-div">
                            <IonButton expand="block" className="pickup-btn footer-btn" onClick={handleSubmit}>
                                Add Product
                            </IonButton>
                        </IonToolbar>
                    </IonFooter>
                </>
            }
            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar center-div">
                            <IonButtons slot="start" onClick={backToServices}>
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="start" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-center">Product Information</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="addincome">
                        <div className="no-network-div center-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true &&
                darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar center-div">
                            <IonButtons slot="start" onClick={backToServices}>
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="start" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle className="ion-text-center">Product Information</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="addincome">
                        <div className="the-form mt-2 center-div">
                            <div className="m-title">
                                <IonLabel className="main-title">Add Your Product Details</IonLabel>
                            </div>
                            <div className="input-container">
                                <input type="text"
                                    id="textAreaExample"
                                    value={title}
                                    onChange={handleTitleInput}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter')
                                            e.preventDefault();
                                    }}
                                    className="form-control" />
                                <label className="label-outline" htmlFor="textAreaExample">Product Title</label>
                            </div>
                            <div className='fontName emailErrorMsg'>{titleErrorMessage}</div>
                            <div className="input-container">
                                <textarea id="textAreaExample"
                                    rows={4}
                                    value={description}
                                    onChange={handleDesInput}
                                    className="form-control text-area-focus">
                                </textarea>
                                <label className="label-outline" htmlFor="textAreaExample">Description</label>
                            </div>
                            <div className='fontName emailErrorMsg'>{desErrorMessage}</div>

                            <div className="m-title">
                                <IonLabel>Add Your Product Image</IonLabel>
                            </div>
                            <div className="product-image-add send-money-holder animate__animated animate__fadeInUp animate__slow">
                                <IonGrid>
                                    <IonRow>
                                        {Device.platform == "iOS" && (
                                            <IonCol size-md="3" size="6">
                                                <div className="contact-holder mt-0" onClick={handlePictureUpload}>
                                                    <div className="add-product ion-text-center">
                                                        <div className="add-btn-holder">
                                                            <img className="plus-btn-icon" src="assets/imgs/icons/plus.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonCol>
                                        )}
                                        {Device.version < "13" && (
                                            <IonCol size-md="3" size="6">
                                                <div className="contact-holder mt-0" onClick={handlePictureUpload}>
                                                    <div className="add-product ion-text-center">
                                                        <div className="add-btn-holder">
                                                            <img className="plus-btn-icon" src="assets/imgs/icons/plus.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonCol>
                                        )}
                                        <IonCol size-md="3" size="6" onClick={handlePictureCapture} className="capture-option">
                                            <div className="contact-holder mt-0">
                                                <div className="add-product ion-text-center">
                                                    <div>
                                                        <img className="camera-btn-icon" src="assets/imgs/icons/camera.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCol>
                                        {capturedImages.map((image, index) => {
                                            return (
                                                <IonCol key={index} size-md="3" size='6'>
                                                    <div className="fontName image-container-add">
                                                        <IonImg className="fontName addProductImg" src={URL.createObjectURL(image)} alt={`Image ${index}`} />
                                                    </div>
                                                </IonCol>
                                            );
                                        })}
                                    </IonRow>
                                </IonGrid>
                                <IonGrid className="add-product-mt m-d-none">
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonButton expand="block" className="pickup-btn footer-btn" onClick={handleSubmit}>
                                                Add Product
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>

                            </div>
                        </div>

                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true} />
                        <IonToast
                            isOpen={showToast}
                            onDidDismiss={() => setShowToast(false)}
                            message={errorMessage}
                            duration={3000}
                            color="danger" />
                        <IonToast
                            isOpen={showToasSuccess}
                            onDidDismiss={() => setShowToast(false)}
                            message={successMessage}
                            duration={3000}
                            color="success" />
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true} />
                        <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
                            <IonContent className="model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={suspendedModalClose} expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                    </IonContent>
                    <IonFooter className="ion-no-border d-d-none">
                        <IonToolbar className="addincome-toolbar center-div toolbar-footer-bg">
                            <IonButton expand="block" className="pickup-btn footer-btn" onClick={handleSubmit}>
                                Add Product
                            </IonButton>
                        </IonToolbar>
                    </IonFooter>
                </>
            }
        </IonPage>
    );
};

export default Addproduct;
